import React, { useState, useEffect, useRef } from "react";
import Page from "../components/Page";
import DossierList from "./DossierList";

const DossierPage = (props) => {
    
    return(
    <Page title={"Dossiers"}>
        <DossierList />
    </Page>
    )
}
export default DossierPage