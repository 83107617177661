import React, { useState, useEffect, useRef } from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import { Box, Button, Container, Paper, Stack, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";

import logo from "../img/logo_big.png";
import { useTranslation } from "react-i18next";
import { useGetCandidateQuery, useUpdateCandidateMutation } from "../rtk/candidates";
import FirstStep from "./FirstStep";
import { ChevronRight } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import { useGetMyDossierQuery } from "../rtk/dossiers";
import ThirdStepSimple from "./ThirdStepSimple";


const SimpleForm = (props) => {
    const user = useSelector((state) => state.user.user);
    const theme = useTheme()
    const { t, i18n } = useTranslation()

    const [step, setStep] = useState(0)

    const { data: candidate, isLoading, isError } = useGetCandidateQuery(user.pk)
    const { data: dossier, isLoading: isLoadingDossier, isError: isErrorDossier } = useGetMyDossierQuery()
    const [updateCandidate, { data, error }] = useUpdateCandidateMutation()

    const [timer, setTimer] = useState(0)
    const [intervalState, setIntervalState] = useState(null)
    const [isRunning, setIsRunning] = useState(false)


    const timeIsUp = () => {
        return timer === 0
    }

    useEffect(() => {
        if (timeIsUp() && isRunning) {
            props.logoff()
        }
    }, [timer])


    useEffect(() => {
        if (user.language) {
            i18n.changeLanguage(user.language)
        }
    }, [user.language])


    useEffect(() => {
        if (candidate) {
            setIsRunning(false)
            setStep(candidate.step)
        }
    }, [candidate])

    useEffect(() => {
        if (isRunning) {
            startTimer()
        }
    }, [isRunning])

    useEffect(() => {
        stopTimer()
    }, [step])


    useEffect(() => {

        switch (step) {
            case 1:
                setTimer(15 * 60)
                break;
            case 2:
                setTimer(5 * 60)
                break;
            case 3:
                setTimer(20 * 60)
                break;
            case 4:
                stopTimer()
                break;
            default:
                return
        }
    }, [step])



    const startTimer = (minutes) => {
        setIntervalState(setInterval(() => {
            if (timer > 0) {
                setTimer((prev) => prev > 0 && prev - 1)
            }
        }, 1000))
    }

    const stopTimer = () => {
        clearInterval(intervalState)
        setTimer(0)
    }

    const renderTimer = () => {
        if (timer > 0) {
            const minutes = Math.floor(timer / 60).toString().padStart(2, "0")
            const seconds = (timer % 60).toString().padStart(2, "0")
            return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Paper sx={{ display: "flex", justifyContent: "center", width: "fit-content", mt: 1, p: 1 }}>
                        <Typography variant="h5" >{minutes}:{seconds}</Typography>
                    </Paper>
                </Box>
            )
        }
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderStep0()
            case 1:
                return <FirstStep isSimple={true} logoff={props.logoff} setIsRunning={setIsRunning} />
            case 2:
                return <SecondStep logoff={props.logoff} setIsRunning={setIsRunning} />
            case 3:
                return <ThirdStepSimple logoff={props.logoff} setIsRunning={setIsRunning} />
            case 4:
                return renderFinalStep()
            default:
                renderDefaultStep()

        }
    }

    const renderDefaultStep = () => {
        return (
            <Stack>
                <Typography variant="h4" sx={{ mb: 2 }}>{t("salutation")} {user.first_name}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("welcome_text")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("signature")}</Typography>
            </Stack>
        )
    }

    const finishStep0 = () => {
        updateCandidate({ id: user.pk, step: 1, step_0: true })
    }


    const renderStep0 = () => {
        // Welcome
        return (
            <Stack>
                <Typography variant="h4" sx={{ mb: 2 }}>{t("salutation")} {user.first_name}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("welcome_text")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("signature")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={finishStep0} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>

            </Stack>
        )
    }

    const renderFinalStep = () => {
        return (
            <Stack>
                <span>
                    <Typography component={"span"} variant="h4" sx={{ mb: 2 }}>{t("final_step_title")} </Typography>
                    <Typography component={"span"} variant="h4" sx={{ mb: 2 }}>{user.first_name}!</Typography>
                </span>
                <br />
                <Typography variant="body1" sx={{ mb: 2 }}>{t("final_step_text")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("final_step_goodbye")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("signature")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={props.logoff} endIcon={<ChevronRight />}>{t("completed")}</Button>
                </Box>
            </Stack>
        )
    }




    const renderHeader = () => {
        return (
            <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{ width: "100%", backgroundColor: "#FFF", p: 2, m: 0, borderBottom: "1px solid #DDDDDD", flexWrap: "wrap" }} >
                <img src={logo} alt="logo" height={isMobile ? 20 : 60} />
                {isMobile ?
                    null
                    :
                    <Stepper activeStep={step === 0 ? 0 : step - 1}>

                        <Step>
                            <StepLabel optional={<Typography variant="caption">25 min</Typography>}>{t("words")}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel optional={<Typography variant="caption">5 min</Typography>}>{t("values")}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel optional={<Typography variant="caption">30 min</Typography>}>{t("qualities")}</StepLabel>
                        </Step>
                        
                    </Stepper>
                }
            </Stack>
        )
    }

    return (
        <>
            <div
                style={{
                    overflowX: "shown",
                    overflowY: "auto",
                }}
            >
                {renderHeader()}
                {renderTimer()}

                <Container
                    maxWidth="xl"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        mt: 4,

                    }}
                >
                    <Paper sx={{ p: 2, m: 1, width: "100%", height: "100%" }}>
                        {renderStep()}
                    </Paper>
                </Container>
            </div>
        </>
    )
}
export default SimpleForm