import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Typography } from "@mui/material";

const PageHeader = ({title,needGoBack=false, subtitle}) => {
    const navigate = useNavigate()

    const getGoBack = () => {
        if (needGoBack) {
            if(typeof needGoBack === "string") {
                return needGoBack
            }
            return -1
        }
        return null
    }

    return (
    <div className="page-title">
        {
            getGoBack() ?
            <Link onClick={() => navigate(getGoBack())} className="back-arrow">
                <FontAwesomeIcon size="xl" icon={faArrowLeft} />
            </Link>
            :
            <a></a>

            
        }
        <Typography variant="h4" >{title}</Typography>
        <Typography variant="h6"> {subtitle}</Typography>
    </div>
    )
    
}
export default PageHeader