import React, { useState, useEffect, useRef } from "react";
import { useGetWordsQuery } from "../rtk/words";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, ChevronRight, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useGetImportancesQuery } from "../rtk/importance";
import { useGetValuesQuery, useGetWordscoresQuery, useSubmitFirstStepMutation, useSubmitSecondStepMutation, useSubmitThirdStepMutation } from "../rtk/form";
import { useTheme } from "@emotion/react";

const ThirdStepSimple = (props) => {
    const { t } = useTranslation()
    const user = useSelector((state) => state.user.user)

    const [isInstructions, setIsInstructions] = useState(true)

    const [results, setResults] = useState([])

    const { data: values } = useGetValuesQuery();
    const { data: scores } = useGetWordscoresQuery();
    const { data: words } = useGetWordsQuery();

    const [open, setOpen] = useState([true, false, false, false]);
    const [turn, setTurn] = useState(0)

    const [submit, response] = useSubmitThirdStepMutation()

    const theme = useTheme()

    const [bestWords, setBestWords] = useState([])

    useEffect(() => {
        if (!!!isInstructions) {
            props.setIsRunning(true)
        }
        else {
            props.setIsRunning(false)
        }
    }, [isInstructions])

    useEffect(() => {
        if (scores) {
            getBestWords()
        }
    }, [scores])

    useEffect(() => {
        // if all value have 3 results set round to 1
        if (values && results.length === 12) {
            setTurn(1)
            setOpen([true, false, false, false])
        }
    }, [results])

    useEffect(() => {
        //Open next accordion if not already open and 3 results in previous accordion
        // Count number of results in previous accordion
        if (turn === 0) {
            for (let i = 0; i < 3; i++) {
                if (results.filter((result) => result.value === values[i].id).length === 3 && !open[i + 1] && results.filter((result) => result.value === values[i + 1].id).length === 0) {
                    setOpen((prev) => {
                        const temp = [...prev]
                        temp[i + 1 % 4] = true
                        temp[i] = false
                        return temp
                    })
                }
            }

        }
        else {
            // restart counting from 3 to 5
            for (let i = 0; i < 3; i++) {
                if (results.filter((result) => result.value === values[i].id).length === 5 && !open[i + 1] && results.filter((result) => result.value === values[i + 1].id).length === 3) {
                    setOpen((prev) => {
                        const temp = [...prev]
                        temp[i + 1] = true
                        temp[i] = false
                        return temp
                    })
                }
            }
        }
    }, [results, turn])


    const getBestWords = () => {
        const categories = [6, 5, 4, 3, 2, 1, 0]
        let temp_bestwords = []
        const nbWords = 20 + 4
        while (temp_bestwords.length < nbWords) {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i]
                const wordsInCategory = scores.filter((word) => word.importance === category)
                if (wordsInCategory.length > 0) {
                    temp_bestwords = [...temp_bestwords, ...wordsInCategory.map((word) => word.word)]
                }
                if (temp_bestwords.length >= nbWords) {
                    break
                }
            }
        }
        setBestWords(temp_bestwords)
    }

    const renderInstructions = () => {
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("third_step_title")}</Typography>
                <Typography variant="body1" textAlign={"justify"}>{t("third_step_text_simple")}</Typography>
                <Typography variant="body1" textAlign={"justify"}>{t("stop_message")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant="outlined" onClick={props.logoff}>{t("stop")}</Button>
                    <Button variant="contained" onClick={() => setIsInstructions(false)} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>
            </Stack>
        )
    }

    const handleResultClick = (word, value) => {
        const temp_results = [...results]
        const index = temp_results.findIndex((el) => el.word === word && el.value === value)
        if (index !== -1) {
            temp_results.splice(index, 1)
        } else {
            temp_results.push({ word: word, value: value })
        }
        setResults(temp_results)
    }

    const getWordInLanguage = (word) => {
        const wordInLanguage = words.find((w) => w.id === word)
        if (!wordInLanguage) return ("")
        if (user.language === "en") {
            return wordInLanguage.word_en
        } else if (user.language === "sp") {
            return wordInLanguage.word_sp
        }
        else {
            return wordInLanguage.word_fr
        }
    }

    const handleSubmit = () => {
        submit(results)
    }

    const isStepCompleted = () => {
        return results.length === 20
    }

    const getIsButtonDisabled = (word, value) => {
        // The word is already selected of max number is selected
        return (
            (
                results.findIndex((el) => el.word === word) !== -1
                &&
                results.findIndex((el) => el.value === value && el.word === word) === -1
            )
            ||
            (
                results.filter(el => el.value === value).length >= 5
                &&
                results.findIndex((el) => el.value === value && el.word === word) === -1)
            ||
            values.findIndex((el) => el.word === word) !== -1
        )
    }

    const getButtonIsSelected = (word, value) => {
        return results.findIndex((el) => el.value === value && el.word === word) !== -1
    }

    const renderStep = () => {
        // Chose 4 words from the best words for each value
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("third_step_title")}</Typography>
                {
                    turn === 0 ?
                        <Typography variant="body1">{t("third_step_recap_instruction_simple_1")}</Typography>
                        :
                        <Typography variant="body1">{t("third_step_recap_instruction_simple_2")}</Typography>
                }
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Stack sx={{ width: "100%" }} >
                        {values.map((value, i) => {
                            return (
                                <Accordion key={value.id} sx={{ p: 0 }} expanded={open[i]} onChange={() => {
                                    setOpen(open.map((el, j) => {
                                        if (i === j) {
                                            return !el
                                        } else {
                                            return el
                                        }
                                    }
                                    ))
                                }}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <span>
                                            <Typography component={"span"} variant="h5">{t('third_step_for_your_value')} </Typography>
                                            <Typography component={"span"} sx={{ fontWeight: "bold", color: theme.palette.primary.main }} variant="h5">{getWordInLanguage(value.word)}</Typography>

                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            {bestWords.map((word) => {
                                                return (
                                                    <Grid item xs={6} md={3} key={word}>
                                                        <Button
                                                            fullWidth
                                                            variant={getButtonIsSelected(word, value.id) ? "contained" : "outlined"}
                                                            disabled={getIsButtonDisabled(word, value.id)}
                                                            onClick={() => handleResultClick(word, value.id)}
                                                            endIcon={getButtonIsSelected(word, value.id) ? <Check /> : null}
                                                        >{getWordInLanguage(word)}</Button>
                                                    </Grid>
                                                )
                                            }
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </Stack>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" disabled={!isStepCompleted()} onClick={handleSubmit} endIcon={<ChevronRight />}>{t("next")}</Button>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack>
            {
                isInstructions ? renderInstructions()
                    : renderStep()
            }
        </Stack>
    )
}
export default ThirdStepSimple