import React, { useState, useEffect, useRef } from "react";
import { useGetWordsQuery } from "../rtk/words";
import { Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useGetImportancesQuery } from "../rtk/importance";
import { useGetWordscoresQuery, useSubmitFirstStepMutation, useSubmitSecondStepMutation } from "../rtk/form";

const SecondStep = (props) => {
    const { t } = useTranslation()

    const user = useSelector((state) => state.user.user)
    const [isInstructions, setIsInstructions] = useState(true)
    const [isRecap, setIsRecap] = useState(false)
    const [results, setResults] = useState([])
    const [bestWords, setBestWords] = useState([])

    const [submit, response] = useSubmitSecondStepMutation()

    const { data: scores } = useGetWordscoresQuery()
    const { data: words } = useGetWordsQuery()


    useEffect(() => {
        if (!!!isInstructions) {
            props.setIsRunning(true)
        }
        else {
            props.setIsRunning(false)
        }
    }, [isInstructions])


    const getBestWords = () => {
        const categories = [6, 5, 4, 3, 2, 1, 0]
        let temp_bestwords = []
        while (temp_bestwords.length < 4) {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i]
                const wordsInCategory = scores.filter((word) => word.importance === category)
                if (wordsInCategory.length > 0) {
                    temp_bestwords = [...temp_bestwords, ...wordsInCategory.map((word) => word.word)]
                }
                if (temp_bestwords.length >= 4) {
                    break
                }
            }
        }
        return temp_bestwords
    }

    useEffect(() => {
        if (scores) {
            setBestWords(getBestWords())
        }
    }, [scores])

    const getWordInLanguage = (word) => {
        if (user.language === "en") {
            return word.word_en
        } else if (user.language === "sp") {
            return word.word_sp
        }
        else {
            return word.word_fr
        }
    }


    const renderInstructions = () => {
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("second_step_title")}</Typography>
                <Typography variant="body1" textAlign={"justify"}>{t("second_step_text")}</Typography>
                {/* <Typography variant="body1" textAlign={"justify"}>{t("stop_message")}</Typography> */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant="outlined" onClick={props.logoff}>{t("stop")}</Button>
                    <Button variant="contained" onClick={() => setIsInstructions(false)} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>
            </Stack>
        )
    }

    const handleSubmit = () => {
        submit(results)

    }

    const renderSecondStep = () => {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body2">{t("second_step_instructions")}</Typography>
                <Grid container sx={{ mt: 2 }}>
                    {
                        bestWords.map((wordId, index) => {
                            return (
                                <Grid item xs={6} md={3} key={wordId}>
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={results.includes(wordId)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setResults([...results, wordId])
                                                    }
                                                    else {
                                                        setResults(results.filter((word) => word !== wordId))
                                                    }
                                                }}
                                                disabled={results.length >= 4 && !results.includes(wordId)}
                                            />
                                        }
                                        label={getWordInLanguage(words.find((word) => word.id == wordId))}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Button variant="contained" onClick={() => setIsRecap(true)} disabled={results.length < 4} endIcon={<ChevronRight />}>{t("next")}</Button>
                </Box>
            </Box>
        )
    }

    const renderRecap = () => {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="h2">{t("second_step_recap")}</Typography>
                <Typography variant="body1">{t("second_step_recap_2")}</Typography>
                <Paper elevation={20} sx={{ mt: 2 }}>
                    <Stack sx={{ p: 2 }} spacing={2} justifyContent={"center"} alignItems={"center"}>
                        {
                            results.map((wordId, index) => {
                                return (
                                    <Typography variant="h3" key={index}>{getWordInLanguage(words.find((word) => word.id == wordId))}</Typography>
                                )
                            })
                        }
                    </Stack>
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Button variant="contained" onClick={handleSubmit} disabled={results.length < 4} endIcon={<ChevronRight />}>{t("next")}</Button>
                </Box>
            </Box>
        )
    }

    return (
        <Stack>
            {
                isInstructions ? renderInstructions()
                    : isRecap ? renderRecap() : renderSecondStep()
            }
        </Stack>
    )
}
export default SecondStep