import React, { useState, useEffect, useRef } from "react";
import RepresentativeList from "./RepresentativeList";
import Page from "../components/Page";

const RepresentativePage = (props) => {
    
    return(
    <Page title={"Représentants"}>
        <RepresentativeList />
    </Page>
    )
}
export default RepresentativePage