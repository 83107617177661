export const pad = num => ("0" + num).slice(-2);

export const getTimeFromDate = dateString => {
    if (dateString) {
        const date = new Date(dateString);
        let hours = date.getHours(), minutes = date.getMinutes(), seconds = date.getSeconds();
        return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    } else {
        return "";
    }
};
export const stringifyTimeDifference = (time) => {
    let hours = Math.floor(time / 3600000);
    time = time - (hours * 3600000);
    let minutes = Math.floor(time / 60000);
    time = time - minutes * 60000;
    let secondes = Math.floor(time / 1000);

    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(secondes).padStart(2, "0");
};

export function searchIsValid(value, searchTerm) {
    if (searchTerm === "") return true
    if (value === null || value === undefined) return false
    
    return value.toLowerCase().replace(/\s/g, "").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").includes(searchTerm.toLowerCase().replace(/\s/g, "").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,""))
};