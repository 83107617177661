import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import api from "../api.js";
import { Grid, Link, Stack, TextField, Typography } from '@mui/material';
import logo from '../img/logo_big.jpg';
import './Login.css';
import { Container } from '@mui/material';
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect';


export const Login = (props) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorLog, setErrorLog] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            
            var res = await api.post('/token/',{ username:username, password:password })
            if(res.status === 200){
              let refreshToken = res.data.refresh;
              let accessToken = res.data.access;
              
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("refreshToken", refreshToken);
              await props.verifyAuth()
            }
            else {
              setErrorLog("Aucun compte actif avec cet identifiant et ce mot de passe")
            }
            
            
        } catch (error) {
          alert(error);
        }
    };

      return(
        <div className="login-wrapper" style={{backgroundColor:"#FFF"}}>

          <Grid container borderRadius={"10px"}
            justifyContent="center"
            alignItems="stretch"
            display={"flex"}
            
          >
            <Grid item xs={12} sm={12} md={12} >
              <img style={{width:"100%", height:"auto"}} className="hero-logo" src={logo} alt="logo"/>
            </Grid>
            <Grid backgroundColor={"#FFF"}  className="login-right" item  xs={12} sm={12} md={12}
            
            >
                <form onSubmit={handleSubmit} style={{width:"100%",height:"100%",padding:"1em" }}>
                  <Stack 
                    spacing={{md:6, sm:3, xs:3}}   
                    justifyContent="space-between"
                    height={"100%"}
                    alignItems="center"
                    

                  >
                    <Typography variant='h4'>Connexion</Typography>
                    <Stack
                      width={(isMobile ? "100%" : "80%")}
                    >
                      <TextField 
                        label="Nom d'utilisateur"
                        autoFocus={true} 
                        className={"form-field " + (errorLog && "invalid")} 
                        placeholder="Nom d'utilisateur" 
                        type="username"
                        fullWidth
                        onChange={e => setUserName(e.target.value)} 
                        variant="standard"/>
                      
                      <TextField
                        label="Mot de passe"
                        type="password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Mot de passe"
                        className={"form-field " + (errorLog && "invalid")}
                        autoComplete="false"
                        fullWidth
                        variant="standard"
                      />
                    </Stack>
                    <Button variant="contained" type="submit">Connexion</Button>
                    <div style={{justifyContent:"center", alignItems:"center", display:"flex"}}>
                        {/* <Link href='/register'>S'enregistrer</Link> */}
                    </div>
                  </Stack>
                  
                </form>

                <Typography style={{ color:"red", textAlign:"center",  m:1}} >{errorLog}</Typography>

            </Grid>
          </Grid>
          
        </div>
      )
}
