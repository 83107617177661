import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Autocomplete, ListItemText, Alert } from '@mui/material';
import { useGetDossiersQuery } from '../rtk/dossiers'
import { useGetClientsQuery } from '../rtk/clients'
import { useGetCandidatesQuery, useSendEmailsMutation } from '../rtk/candidates'
import Page from '../components/Page';
import api from '../api';
import { Close } from "@mui/icons-material";

const SendEmailsView = (props) => {
    const { data: clients, isLoading, error } = useGetClientsQuery()
    const { data: dossiers, isLoading: isLoadingDossiers, error: errorDossiers } = useGetDossiersQuery()
    const { data: candidates, isLoading: isLoadingCandidates, error: errorCandidates, refetch } = useGetCandidatesQuery()
    const [sendEmails, response]= useSendEmailsMutation()

    const [client, setClient] = useState(null)
    const [dossier, setDossier] = useState(null)

    const [snackMessage, setSnackMessage] = useState(null)

    useEffect(() => {
        refetch()
    }, [client, dossier])

    useEffect(() => {
        if (response.isSuccess) {
            setSnackMessage("Les courriels ont été envoyés avec succès.")
            refetch()
        }
        else if (response.isError) {
            setSnackMessage("Une erreur est survenue lors de l'envoie des courriels.")
            refetch()
        }
    }, [response])


    const handleClientChange = (event, value) => {
        setClient(value)
        setDossier(null)
    }

    if (isLoading || isLoadingDossiers) {
        return (
            <Page title={"Envoye de courriels"}>
                <Paper elevation={9}>
                    <Skeleton variant="rectangular" width="100%" height={118} />
                    <Box p={2}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Box>
                </Paper>
            </Page>
        )
    }

    const handleSendEmails = async () => {
        const res = await sendEmails({ dossier: dossier.id })
    }

    return (
        <Page title={"Envoi de courriels"}>
            <Paper elevation={9}>
                <Typography
                    variant="body1"
                    textAlign={"center"}
                    sx={{ mt: 2 }}
                >
                    Envoyez un courriel à tous les candidats d'un dossier qui n'ont pas déjà été notifiés.
                </Typography>
                <Typography
                    variant="body1"
                    textAlign={"center"}
                    sx={{ mt: 2 }}
                >
                    *Les mots de passe seront regénérés automatiquement pour ces candidats.
                </Typography>
                <Divider sx={{ mt: 2 }} />
                <Box p={2}>
                    <Stack spacing={2}>
                        <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                            <Autocomplete
                                id="combo-box-client"
                                options={clients}
                                fullWidth
                                getOptionLabel={(option) => option ? option.name : ""}
                                renderInput={(params) => <TextField {...params} label="Client" />}
                                onChange={handleClientChange}
                                value={client}
                            />
                            <Autocomplete
                                id="combo-box-dossier"
                                options={dossiers.filter(dossier => dossier.client === client?.id)}
                                fullWidth
                                disabled={client === null}
                                getOptionLabel={(option) => option ? option.name : ""}
                                renderInput={(params) => <TextField {...params} label="Dossier" />}
                                onChange={(event, value) => setDossier(value)}
                                value={dossier}
                            />
                        </Stack>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" onClick={handleSendEmails} disabled={dossier === null} sx={{ width: "fit-content" }}>Envoyer</Button>
                        </Box>
                    </Stack>
                </Box>
            </Paper>
            {
                dossier !== null &&
                <Stack spacing={3} justifyContent={"center"} alignContent={"center"} sx={{ width: "100%", mt: 2 }}>
                    <Typography variant="h5" textAlign={"center"}>Candidats</Typography>
                    <Stack spacing={1} justifyContent={"center"} alignContent={"center"} sx={{ width: "100%" }}>
                        {candidates?.filter(candidate => candidate.dossier === dossier?.id && !!!candidate.is_notified && candidate.is_active).map(candidate => (
                            <Typography
                                key={candidate.id}
                                variant="body1"
                                textAlign={"center"}
                            >
                                {candidate.first_name + " " + candidate.last_name}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackMessage !== null}
                autoHideDuration={6000}
                onClose={() => setSnackMessage(null)}
                message={snackMessage}
            >
                <Alert
                    onClose={() => setSnackMessage(null)}
                    severity={snackMessage === "Les courriels ont été envoyés avec succès." ? "success" : "error"}
                    sx={{ width: '100%' }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>


        </Page>
    )
}
export default SendEmailsView