import React, { useState, useEffect, useRef } from "react";
import PageHeader from "./PageHeader";

import "./Page.css"
import { Container } from "@mui/material";

const Page = ({children, title, needGoBack, subtitle, style, maxWidth="xl", fixed=false}) => {
    

    return (
        <Container style={style} maxWidth={maxWidth} fixed={fixed} className="full-height full-page">
            {(title || subtitle || needGoBack) &&
            <PageHeader subtitle={subtitle} title={title} needGoBack={needGoBack} />}
            {children}
        </Container>
    )
}
export default Page