import { Button, Container, Grid, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import PasswordTextField from "../components/PasswordTextField";
import api from "../api";
import { useRegisterMutation } from "../rtk/register";

const RegisterView = (props) => {
    
    const [data, setData] = useState({
        first_name:"",
        last_name:"",
        email:"",
        password:"",
        password2:"",
    });

    const [register, response] = useRegisterMutation();

    const [message, setMessage] = useState([])

    useEffect(() => {
        if(response.isUninitialized){
            return
        }
        if(response.error) {

            const errors = Object.values(response.error.data).map((el,i) => {
                return <Typography key={i} color={"#990000"}>
                    {el}
                </Typography>
            })

            setMessage(errors)
        }
        else if(response.isSuccess) {
            setMessage([<Typography color={"#009900"}>Votre compte a été créé. Veuillez vérifier votre boite de courriels pour valider votre compte</Typography> ])
        }
    
    }, [response])

    const handleSubmit = (e) => {
        e.preventDefault();
        register(data)
    }


    return(
    <Container maxWidth="md" sx={{height:"100%", p:10}}>
        <Paper elevation={6} sx={{p:4}} component={"form"} onSubmit={handleSubmit}>
            <Typography variant="h2">Création de comptes</Typography>
            <Grid container spacing={4} pt={4}>
                <Grid item xs={6}>
                    <TextField
                        label='Prénom'
                        fullWidth
                        required
                        name="first_name"
                        value={data['first_name']}
                        onChange={(e) => setData({...data, [e.target.name]:e.target.value,})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label='Nom de famille'
                        fullWidth
                        required
                        name="last_name"
                        value={data['last_name']}
                        onChange={(e) => setData({...data, [e.target.name]:e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Email'
                        type="email"
                        fullWidth
                        required
                        name="email"
                        value={data['email']}
                        onChange={(e) => setData({...data, [e.target.name]:e.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PasswordTextField
                        label='Mot de Passe'
                        fullWidth
                        name="password"
                        required
                        value={data['password']}
                        onChange={(e) => setData({...data, [e.target.name]:e.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PasswordTextField
                        label='Confirmer votre mot de Passe'
                        fullWidth
                        required
                        name="password2"
                        value={data['password2']}
                        onChange={(e) => setData({...data, [e.target.name]:e.target.value})}
                    />
                </Grid>
                <Grid item container xs={12} justifyContent={'end'}>
                    <Button type="submit" variant="contained" color="success">S'enregistrer</Button>
                </Grid>
                <Grid item container xs={12} justifyContent={'center'}>
                    <Stack sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                     {message.map((el) => (
                       el
                    ))}
                        <Link href="/login">Retour vers la connexion</Link>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    </Container>
    )
}
export default RegisterView