import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Tooltip, IconButton, List, ListItemButton, Skeleton, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, ListItem } from '@mui/material';
import SearchBar from "../components/SearchBar.jsx";
import { Add, Cancel, CheckCircle, Circle, Close } from "@mui/icons-material";
import { useGetCandidatesQuery } from "../rtk/candidates.js";
import { Link, useNavigate } from "react-router-dom";
import { useGetClientsQuery } from "../rtk/clients.js";
import { useGetDossiersQuery } from "../rtk/dossiers.js";
import { searchIsValid } from "../utils.jsx";
import { useSelector } from "react-redux";

const CandidatesList = (props) => {
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [activeOnly, setActiveOnly] = useState(true);

    const user = useSelector((state) => state.user.user);


    const { data: candidates, isLoading } = useGetCandidatesQuery({}, { skip: props.candidates });
    const { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    const { data: dossiers, isLoading: isLoadingDossiers } = useGetDossiersQuery();

    const filterFunction = (candidate) => {
        const name = candidate.first_name + " " + candidate.last_name;
        return (
            (!activeOnly || candidate.is_active) &&
            searchIsValid(name, search)
        )
    }

    const renderStep = (step, title) => {
        return (<Grid item xs={1}>
            <Stack direction={"column"} sx={{ justifyContent: "center", alignItems: "center" }} >
                <Typography variant={"caption"}>{title}</Typography>
                {
                    step ?
                        <CheckCircle color={"success"} />
                        :
                        <Circle />

                }
            </Stack>
        </Grid>)
    }

    return (
        <Box sx={{ ...props.sx }}>
            <SearchBar searchValue={search} searchOnChange={(e) => setSearch(e.target.value)} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={activeOnly}
                            onChange={(e) => setActiveOnly(e.target.checked)}
                            name="activeOnly"
                            color="primary"
                        />
                    }
                    label="Actifs seulement"
                />
                {
                    user.is_staff &&
                    <Tooltip title="Créer un nouveau candidate">
                        <IconButton onClick={() => navigate('/candidates/add')} >
                            <Add />
                        </IconButton>
                    </Tooltip>
                }
            </SearchBar>
            <Paper sx={{ mt: 2 }} elevation={10}>
                <List>
                    {
                        candidates ? candidates.filter(filterFunction).map((candidate, index) => {
                            const dossier = dossiers ? dossiers.find(dossier => dossier.id === candidate.dossier) : null;
                            const client = clients && dossier ? clients.find(client => client.id === dossier.client) : null;
                            return (
                                <span key={index}>
                                    <ListItem key={candidate.id}
                                        secondaryAction={
                                            dossier?.type === 1 ?
                                                <Button disabled={!!!candidate.step_5} sx={{ ml: 1, width: "8em" }} onClick={() => {
                                                    window.open(`/cards/${candidate.pk}/standard`, 'newwindow', 'width=800,height=1200')
                                                    return false;
                                                }
                                                } target="_blank" rel="noopener noreferrer" variant={"outlined"}>Carte Standard</Button>
                                                :
                                                <Button disabled={!!!candidate.step_3} sx={{ ml: 1, width: "8em" }} onClick={() => {
                                                    window.open(`/cards/${candidate.pk}/simple`, 'newwindow', 'width=800,height=500')
                                                    return false;
                                                }
                                                } target="_blank" rel="noopener noreferrer" variant={"outlined"}>Carte Simple</Button>

                                        }
                                    >
                                        <ListItemButton onClick={() => navigate(`/candidates/${candidate.pk}`)}>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
                                                <Grid container justifyContent="space-between" alignItems="center" spacing={2} sx={{
                                                    width: "100%",
                                                }}>
                                                    <Grid item md={2} xs={6} >
                                                        <Typography textOverflow={"ellipsis"}>{candidate.first_name + " " + candidate.last_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={6} >
                                                        {
                                                            candidate.is_notified ?
                                                                <Typography sx={{ color: "success.main" }}>Averti</Typography>
                                                                :
                                                                <Typography sx={{ color: "error.main" }}>Non averti</Typography>

                                                        }
                                                    </Grid>

                                                    <Grid item md={2} xs={6} container>
                                                        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                                            <Typography>{dossier?.name}</Typography>
                                                            <Typography>({client?.name})</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} container alignItems={"end"}>
                                                        <Typography>{"Étapes:"}</Typography>
                                                        {
                                                            dossier?.type === 1 ?
                                                                <>
                                                                    {renderStep(candidate.step_1, "1")}
                                                                    {renderStep(candidate.step_2, "2")}
                                                                    {renderStep(candidate.step_3, "3")}
                                                                    {renderStep(candidate.step_4, "4")}
                                                                    {renderStep(candidate.step_5, "5")}
                                                                </>
                                                                :
                                                                <>
                                                                    {renderStep(candidate.step_1, "1")}
                                                                    {renderStep(candidate.step_2, "2")}
                                                                    {renderStep(candidate.step_3, "3")}
                                                                </>

                                                        }
                                                    </Grid>

                                                </Grid>
                                                <Typography>{!!!candidate.is_active && "inactif"}</Typography>
                                            </Stack>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </span>
                            )
                        })
                            :
                            <Skeleton />

                    }
                </List>
            </Paper>
        </Box>
    )
}
export default CandidatesList