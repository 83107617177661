import { rtkapi } from './rtkapi';

const languageapi = rtkapi.enhanceEndpoints({addTagTypes: ['Language']}).injectEndpoints({
   endpoints: (build) => ({
      getLanguages: build.query({
         query: (params) => ({ url: '/languages/', method: 'get', params }),
         providesTags: ['Language']
      }),
      getLanguage: build.query({
         query: (id) => ({ url: `/languages/${id}/`, method: 'get' }),
         providesTags: ['Language']
      }),
      createLanguage: build.mutation({
         query: (data) => ({ url: '/languages/', method: 'post', data:data }),
         invalidatesTags: ['Language']
      }),
      updateLanguage: build.mutation({
         query: ({id, ...data}) => ({ url: `/languages/${id}/`, method: 'patch', data:data }),
         invalidatesTags: ['Language']
      }),
      deleteLanguage: build.mutation({
         query: (id) => ({ url: `/languages/${id}/`, method: 'delete' }),
         invalidatesTags: ['Language']
      }),
   }),
})

export const {
   useGetLanguagesQuery,
   useGetLanguageQuery,
   useCreateLanguageMutation,
   useUpdateLanguageMutation,
   useDeleteLanguageMutation
} = languageapi; 
