import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import SearchBar from "../components/SearchBar.jsx";
import { Add, Edit } from "@mui/icons-material";
import { useGetWordsQuery, useUpdateWordMutation } from "../rtk/words.js";
import { DataGrid } from '@mui/x-data-grid';
import { searchIsValid } from "../utils.jsx";


const WordsListItem = ({ word }) => {
    const [edit, setEdit] = useState(false);

    return (
        <TableRow
            key={word.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" >
                {word.id}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ borderLeft: "1px solid black" }}>
                <TextField
                    fullWidth
                    value={word.word_fr}
                    onChange={(e) => console.log(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            "&:before": {
                                borderBottom: "none"
                            },
                            "&:after": {
                                borderBottom: "none"
                            }
                        }
                    }}
                    disabled={!edit}
                    variant="standard"
                />
            </TableCell>
            <TableCell sx={{ borderLeft: "1px solid black" }}>
                <TextField
                    fullWidth
                    value={word.word_en}
                    onChange={(e) => console.log(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            "&:before": {
                                borderBottom: "none"
                            },
                            "&:after": {
                                borderBottom: "none"
                            }
                        }
                    }}
                    disabled={!edit}
                    variant="standard"
                />
            </TableCell>
            <TableCell sx={{ borderLeft: "1px solid black" }}>
                <TextField
                    fullWidth
                    value={word.word_sp}
                    onChange={(e) => console.log(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            "&:before": {
                                borderBottom: "none"
                            },
                            "&:after": {
                                borderBottom: "none"
                            },
                            height: "100%",
                        }
                    }}
                    disabled={!edit}
                    variant="standard"
                />
            </TableCell>
            <TableCell sx={{ borderLeft: "1px solid black" }}>
                <Switch
                    checked={word.is_simple}
                    onChange={(e) => console.log(e.target.checked)}
                    name="is_simple"
                    disabled={!edit}
                />
            </TableCell>
            <TableCell sx={{ borderLeft: "1px solid black", justifyContent: "end", display: "flex" }}>
                <Tooltip title="Modifier">
                    <IconButton onClick={() => setEdit(!edit)}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </TableCell>

        </TableRow>
    )
}

const WordsList = (props) => {

    const [search, setSearch] = useState("");
    const [simpleOny, setSimpleOnly] = useState(false);
    const datagridRef = useRef(null);

    const [updateWord, { data: updatedWord, isLoading: isUpdating }] = useUpdateWordMutation();


    const [columns, setColumns] = useState([
        { field: 'id', headerName: 'ID', width: 80, type: 'number', editable: false },
        { field: 'word_fr', headerName: 'Français', width: 200, type: 'text', editable: true },
        { field: 'word_en', headerName: 'Anglais', width: 200, type: 'text', editable: true },
        { field: 'word_sp', headerName: 'Espagnol', width: 200, type: 'text', editable: true },
        { field: 'is_simple', headerName: 'Simple', width: 80, type: 'boolean', editable: true },
    ]);

    const [selectedWords, setSelectedWords] = useState([]);



    function filterFunction(word) {
        return (simpleOny ? word.is_simple : true) && (searchIsValid(word.word_fr, search) || searchIsValid(word.word_en, search) || searchIsValid(word.word_sp, search))
    }

    const { data: words, isLoading } = useGetWordsQuery();

    const handleEditCellChangeCommitted = React.useCallback(
        (params, event) => {
            let data = event.target.value;
            if (params.field === "is_simple") {
                data = event.target.checked;
            }
            const word = words.find((word) => word.id === params.id);
            if (word[params.field] !== data) {
                updateWord({ id: params.id, [params.field]: data });
            }
        },
        [words, updateWord],
    );

    return (
        <Box>
            <SearchBar searchValue={search} searchOnChange={(e) => setSearch(e.target.value)} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={simpleOny}
                            onChange={(e) => setSimpleOnly(e.target.checked)}
                            name="simpleOny"
                            color="primary"
                        />
                    }
                    label="Simples seulement"
                />
            </SearchBar>
            {/* {words &&
                <TableContainer
                    sx={{
                        width: "100%",
                        maxHeight: "calc(100vh - 200px)",
                        mt: 2
                    }}
                    component={Paper}
                    elevation={10}
                >
                    <Table stickyHeader>
                        <TableHead component={Paper}  >
                            <TableRow >
                                <TableCell sx={{ backgroundColor: "#a6acad" }} >
                                    ID
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell sx={{ borderLeft: "1px solid black", backgroundColor: "#a6acad" }}>
                                        {column.headerName}
                                    </TableCell>
                                ))}
                                <TableCell sx={{ borderLeft: "1px solid black", backgroundColor: "#a6acad" }}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {words.map((word) => (
                                <WordsListItem word={word} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            } */}
            {words &&
            <Paper elevation={10} sx={{ width: "100%", mt: 2 }}>
                <DataGrid
                    ref={datagridRef}
                    rows={words.filter(filterFunction)}
                    columns={columns}
                    onRowEditStop={(e) => console.log(e)}
                    onCellEditStop={handleEditCellChangeCommitted}
                    pageSizeOptions={[100]}
                    initialState={{ sortModel: [{ field: 'id', sort: 'asc' }] }}
                    sx={{ width: "100%", height: "calc(100vh - 200px)" }}
                />
            </Paper>
            }
        </Box>
    )
}
export default WordsList