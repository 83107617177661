import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, Snackbar } from '@mui/material';
import { useCreateClientMutation } from "../rtk/clients";

const ClientsAdd = (props) => {

    const [createClient, response] = useCreateClientMutation();
    const [data, setData] = useState({name:""});
    
    const handleCreate = (e) => {
        e.preventDefault();
        createClient(data);
    }

    useEffect(() => {
        if (response.isSuccess) {
            if(props.onSuccess) props.onSuccess()
            setData({name:""});
        }
        else {
            if(props.onError) props.onError()
        }
    }, [response])


    return(
    <Box  onSubmit={handleCreate} component={"form"} sx={{...props.sx}}>
        <Stack spacing={2} sx={{marginTop:"1em"}}>
            <TextField
                id="name"
                label="Nom du client"
                variant="outlined"
                value={data.name}
                required
                onChange={(e) => setData({...data, name:e.target.value})}
            />
            <Button variant="contained" type="submit">Ajouter</Button>
        </Stack>
        <Snackbar color="error" open={response.isError} message={response.error?.data && response.error?.data.length < 200  ? JSON.stringify(response.error.data) : "Erreur" } autoHideDuration={60} />
    </Box>
    )
}
export default ClientsAdd