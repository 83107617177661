import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Tooltip, IconButton, List, ListItemButton, Skeleton, ListItem } from '@mui/material';
import SearchBar from "../components/SearchBar.jsx";
import { Add, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useGetDossiersQuery } from "../rtk/dossiers";
import { searchIsValid } from "../utils.jsx";


const DossierList = (props) => {
    const navigate = useNavigate();

    const { data: dossiers, isLoading: dossiersLoading } = useGetDossiersQuery();
    const [search, setSearch] = useState("");
    const [activeOnly, setActiveOnly] = useState(true);

    const filterFunction = (dossier) => {
        return (
            (!activeOnly || dossier.is_active) &&
            searchIsValid(dossier.name, search)
        )
    }

    return (
        <Box sx={{ ...props.sx }}>
            <SearchBar searchValue={search} searchOnChange={(e) => setSearch(e.target.value)} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={activeOnly}
                            onChange={(e) => setActiveOnly(e.target.checked)}
                            name="activeOnly"
                            color="primary"
                        />
                    }
                    label="Actifs seulement"
                />
                <Tooltip title="Créer un nouveau candidate">
                    <IconButton onClick={() => navigate('/dossiers/add')} >
                        <Add />
                    </IconButton>
                </Tooltip>
            </SearchBar>
            <Paper sx={{ mt: 2 }} elevation={10}>
                <List>
                    {
                        dossiers ? dossiers.filter(filterFunction).map((dossier, index) => {
                            return (
                                    <ListItemButton key={index} onClick={() => navigate(`/dossiers/${dossier.id}`)}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
                                            width: "100%",
                                        }}>
                                            <Typography>{dossier.name}</Typography>
                                            <Typography>{!!!dossier.is_active && "inactif"}</Typography>
                                        </Stack>
                                    </ListItemButton>
                            )
                        })
                            :
                            <Skeleton />

                    }
                </List>
            </Paper>

        </Box>
    )
}
export default DossierList