import React, { useState, useEffect, useRef } from "react";
import { useGetWordsQuery } from "../rtk/words";
import { Box, Button, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useGetImportancesQuery } from "../rtk/importance";
import { useSubmitFirstStepMutation } from "../rtk/form";



const DraggableImportance = (props) => {
    const { importanceValue, importanceText, setImportance } = props
    const { t } = useTranslation()

    return (
        <Draggable draggableId={"importance-" + importanceValue} index={importanceValue}>
            {(provided, snapshot) => (
                <>
                    <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => setImportance(importanceValue)}

                        sx={{
                            p: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            display: "flex",
                            borderRadius: 1,
                            mb: 1,
                            height: 50,
                            backgroundColor: snapshot.isDragging ? "primary.main" : "primary.light",
                            color: "white",
                            ...provided.draggableProps.style,
                            '&:hover': {
                                backgroundColor: "primary.main",
                                color: "white",
                            },
                        }}
                        style={{
                            ...provided.draggableProps.style,
                            transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)',
                        }}
                    >
                        <Typography variant="body2">{t(importanceText)}</Typography>
                    </Paper>
                    {snapshot.isDragging ?
                        <Paper
                            sx={{
                                p: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                mb: 1,
                                borderRadius: 1,
                                height: 50,
                                backgroundColor: "primary.main",
                                color: "white",
                                transform: "none"
                            }}
                        >
                            <Typography variant="body2">{t(importanceText)}</Typography>
                        </Paper>
                        : null
                    }
                </>
            )}
        </Draggable>
    )
}


const FirstStep = (props) => {
    const isSimple = props.isSimple

    const user = useSelector((state) => state.user.user)
    const { data: words } = useGetWordsQuery({ is_simple: isSimple })
    const [isInstructions, setIsInstructions] = useState(true)
    const { t } = useTranslation()
    const [results, setResults] = useState([])

    const { data: importances } = useGetImportancesQuery()
    const [submit, response] = useSubmitFirstStepMutation()

    const [activeWords, setActiveWords] = useState([])





    useEffect(() => {
        if (words) {
            setResults(words.map((word) => {
                return {
                    word: word,
                    importance: null,
                    candidat: user.pk
                }
            }))
        }
    }, [words])

    useEffect(() => {
        if (results) {
            setActiveWords(getWords())
        }
    }, [results])

    useEffect(() => {
        if (response.data) {
            props.setStep(2)
        }
    }, [response.data])

    useEffect(() => {
        if (results.every((result) => result.importance !== null) && results.length > 0) {
            submit(results)
        }
    }, [results])

    useEffect(() => {
        if (!!!isInstructions) {
            props.setIsRunning(true)
        }
        else {
            props.setIsRunning(false)
        }
    }, [isInstructions])

    const renderInstructions = () => {
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("first_step_title")}</Typography>
                {
                    isSimple ?
                    <>
                    <Typography variant="body1" textAlign={"justify"}>{t("first_step_text_first_part_simple")}</Typography>
                    <Typography variant="body1" textAlign={"justify"}>{t("first_step_text_second_part_simple")}</Typography>
                </>
                        :
                        <>
                            <Typography variant="body1" textAlign={"justify"}>{t("first_step_text_first_part")}</Typography>
                            <Typography variant="body1" textAlign={"justify"}>{t("first_step_text_second_part")}</Typography>
                        </>
                }
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" onClick={() => setIsInstructions(false)} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>
            </Stack>
        )
    }

    //Get 7 words where importance is null
    const getWords = () => {
        return results.filter((result) => result.importance === null).slice(0, 7)
    }

    const getWordInLanguage = (word) => {
        if (user.language === "en") {
            return word.word_en
        } else if (user.language === "sp") {
            return word.word_sp
        }
        else {
            return word.word_fr
        }
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }
        const newWords = [...activeWords]
        const word = newWords.find((word) => word.word.id.toString() === destination.droppableId.replace("word-", ""))

        if (word) {
            const importance = parseInt(draggableId.replace("importance-", ""))
            word.importance = importance
            setActiveWords(newWords)
        }
    }

    const setImportance = (importance) => {
        const newWords = [...activeWords]
        //Find first word with no importance
        const word = newWords.find((word) => word.importance === null)
        if (word) {
            word.importance = importance
            setActiveWords(newWords)
        }
    }

    const updateResults = () => {
        const newResults = [...results]
        activeWords.forEach((word) => {
            const index = newResults.findIndex((result) => result.word.id === word.word.id)
            newResults[index] = word
        })
        console.log(newResults)
        setResults(newResults)
    }

    const renderFirstStep = () => {

        return (
            <Box >
                <Typography variant="h5" sx={{ mb: 2 }}>{t("first_step_recap_instruction_1")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("first_step_recap_instruction_2")}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{t("first_step_recap_instruction_3")}</Typography>
                <LinearProgress variant="determinate" value={(results.filter(el => el.importance !== null).length / results.length) * 100} sx={{ mb: 2 }} />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                        <Stack sx={{ width: "66%" }} >
                            {
                                activeWords.map((word, index) => {
                                    const active = (index == 0) || activeWords[index - 1].importance !== null
                                    const current = active && word.importance === null
                                    const importance = word.importance !== null ? importances.find(el => el[0] === word.importance)[1] : null
                                    return (
                                        <Grid container key={word.word.id} sx={{ mb: 4 }} spacing={1}>
                                            <Grid item xs={6} container alignItems={"center"}>
                                                {
                                                    current ?
                                                        <Paper sx={{
                                                            p: 1, borderRadius: 1, height: 50, backgroundColor: "primary.main", color: "white",
                                                            display: "flex", justifyContent: "start", alignItems: "center", width: "100%"

                                                        }}>
                                                            <Typography variant="body2" sx={{ mr: 1 }}>{getWordInLanguage(word.word)}</Typography>
                                                        </Paper>
                                                        :

                                                        <Typography variant="body2" sx={{ mr: 1 }}>{getWordInLanguage(word.word)}</Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Droppable droppableId={"word-" + word.word.id} isDropDisabled={!active}>
                                                    {(provided, snapshot) => (
                                                        <Paper
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            sx={{
                                                                backgroundColor: snapshot.isDraggingOver ? "primary.main" : ((!current) ? "primary.light" : "white"),
                                                                borderRadius: 1,
                                                                p: 2,
                                                                height: 50,
                                                                color: "white",
                                                                display: "flex",
                                                                visibility: active ? "visible" : "hidden",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            elevation={4}
                                                            className={(current) ? "ripple" : null}
                                                        >
                                                            {
                                                                word.importance !== null ?
                                                                    <>
                                                                        {!!!snapshot.isDraggingOver && <Typography variant="body2">{t(importance)}</Typography>}
                                                                        <Box sx={{ visibility: "hidden", height: 0 }}>
                                                                            {provided.placeholder}
                                                                        </Box>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {provided.placeholder}
                                                                    </>
                                                            }
                                                        </Paper>
                                                    )}
                                                </Droppable>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Stack>
                        <Paper sx={{ width: "23%", height: "fit-content", p: 1, minWidth: "8em" }} elevation={10}>
                            <Typography variant="h6">{t("importances")}</Typography>
                            <Droppable droppableId={"importances"} isDropDisabled={true}>
                                {(provided, snapshot) => (
                                    < Stack
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}

                                    >
                                        {

                                            importances?.map((importance) => {
                                                return (
                                                    <DraggableImportance
                                                        key={importance}
                                                        word={importance.id}
                                                        importanceValue={importance[0]}
                                                        importanceText={importance[1]}
                                                        setImportance={setImportance}
                                                    />
                                                )
                                            })
                                        }
                                        <Box sx={{ visibility: "hidden", height: 0 }}>
                                            {provided.placeholder}
                                        </Box>
                                    </Stack>
                                )}
                            </Droppable>
                        </Paper>
                    </Stack>
                </DragDropContext >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {
                        activeWords.every((word) => word.importance !== null) ?
                            <Button variant="contained" onClick={() => {
                                updateResults()
                            }
                            } endIcon={<ChevronRight />}>{t("next")}</Button>
                            :
                            <Button variant="contained" disabled>{t("next")}</Button>
                    }
                </Box>
            </Box>
        )
    }


    return (
        <Stack>
            {
                isInstructions ? renderInstructions()
                    : renderFirstStep()
            }
        </Stack>
    )
}
export default FirstStep