import React, { useState, useEffect, useRef } from "react";
import Page from "../components/Page";
import WordsList from "./WordsList";

const WordsPage = (props) => {
    
    return(
    <Page title={"Mots"}>
        <WordsList />
    </Page>
    )
}
export default WordsPage