import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider } from '@mui/material';
import Page from '../components/Page.jsx';
import ClientsList from "./ClientsList.jsx";
import { useGetClientsQuery } from "../rtk/clients.js";


const ClientsPage = (props) => {

    
    return(
    <Page title={"Clients"}>
        <ClientsList />
    </Page>
    )
}
export default ClientsPage