import { rtkapi } from "./rtkapi"

const clientapi = rtkapi.enhanceEndpoints({addTagTypes: ["Client"]}).injectEndpoints({
    endpoints: (build) => ({
        getClients: build.query({
            query: (params=null) => ({ url: '/clients/', method: 'get', params:params}),
            providesTags:['Client']
        }),

        getClient: build.query({
            query: (id) => ({ url: `/clients/${id}`, method: 'get'}),
            providesTags:['Client']
        }),

        createClient: build.mutation({
            query: (data) => ({url: '/clients/', method:'post', data:data}),
            invalidatesTags:['Client']

        }),

        updateClient: build.mutation({
            query: ({id,...data}) => ({url: `/clients/${id}/`, method:'patch', data:data}),
            invalidatesTags:['Client']

        }), 

        deleteClient: build.mutation({
            query: (id) => ({url: `/clients/${id}/`, method:'delete'}),
            invalidatesTags:['Client']
        }),
            
        
    })
})

export const { useGetClientsQuery, useCreateClientMutation, useUpdateClientMutation, useBatchImportMutation, useGetClientQuery, useDeleteClientMutation } = clientapi
