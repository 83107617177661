import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton } from '@mui/material';
import SearchBar from "../components/SearchBar";
import { useNavigate } from "react-router-dom";
import { useGetClientsQuery } from "../rtk/clients";
import { useGetRepresentativesQuery } from "../rtk/representatives";
import { Add } from "@mui/icons-material";
import { searchIsValid } from "../utils";

const RepresentativeList = (props) => {
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [activeOnly, setActiveOnly] = useState(true);

    const { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    const { data: representatives, isLoading: isLoadingRepresentatives } = useGetRepresentativesQuery();

    const filterFunction = (client) => {
        const name = client.first_name + " " + client.last_name
        return (
            (!activeOnly || client.is_active) &&
            searchIsValid(name, search)
        )
    }

    return (
        <Box sx={{ ...props.sx }}>
            <SearchBar searchValue={search} searchOnChange={(e) => setSearch(e.target.value)} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={activeOnly}
                            onChange={(e) => setActiveOnly(e.target.checked)}
                            name="activeOnly"
                            color="primary"
                        />
                    }
                    label="Actifs seulement"
                />
                <Tooltip title="Créer un nouveau candidate">
                    <IconButton onClick={() => navigate('/representatives/add')} >
                        <Add />
                    </IconButton>
                </Tooltip>
            </SearchBar>
            <Paper sx={{ mt: 2}} elevation={10}>
                <List>
                    {
                        representatives ? representatives.filter(filterFunction).map((representative, index) => {
                            const client = clients?.find(client => client.id === representative.client)
                            return (
                                <ListItemButton key={index} onClick={() => navigate(`/representatives/${representative.pk}`)}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
                                        width: "100%",
                                    }}>
                                        <Typography>{representative.first_name + " " + representative.last_name} ({client?.name})</Typography>
                                        <Typography>{!!!representative.is_active && "inactif"}</Typography>
                                    </Stack>
                                </ListItemButton>
                            )
                        })
                            :
                            <Skeleton />
                    }
                </List>
            </Paper>

        </Box>
    )
}
export default RepresentativeList