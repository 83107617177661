import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, Autocomplete, FormControlLabel, Skeleton, Snackbar, Switch } from '@mui/material';
import Page from "../components/Page";
import { useGetLanguagesQuery } from "../rtk/language";
import MuiPhoneNumber from "mui-phone-number";
import { useGetDossiersQuery } from "../rtk/dossiers";
import { useGetCandidateQuery, useUpdateCandidateMutation } from "../rtk/candidates";
import { useParams } from "react-router-dom";
import PasswordTextField from "../components/PasswordTextField";
import { useChangePasswordMutation } from "../rtk/register";
import { useSelector } from "react-redux";

const CandidateDetails = (props) => {

    const { candidateId } = useParams()

    const { data: languages, isLoading: languagesLoading } = useGetLanguagesQuery();
    const { data: dossiers, isLoading: dossiersLoading } = useGetDossiersQuery();
    const { data: remoteCandidate, isLoading: remoteCandidateeLoading } = useGetCandidateQuery(candidateId, { skip: !candidateId });

    const [updateCandidate, response] = useUpdateCandidateMutation();
    const [updatePassword, responsePassword] = useChangePasswordMutation();

    const user = useSelector(state => state.user.user)

    const [candidate, setCandidate] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        username: "",
        password: "",
        password2: "",
        is_active: true,
        is_notified: false,
    });

    useEffect(() => {
        if (remoteCandidate) {
            setCandidate(remoteCandidate)
        }
    }, [remoteCandidate])

    const handleSubmit = (e) => {
        e.preventDefault();
        updateCandidate({ id: candidateId, ...candidate });
    }

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        updatePassword({ pk: candidateId, password: candidate.password, password2: candidate.password2 });
    }

    return (
        <Page title={candidate.first_name + " " + candidate.last_name} needGoBack={"/candidates/"}>
            <Box component={"form"} onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations personnelles</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="first_name"
                                name="first_name"
                                label="Prénom"
                                disabled={!user.is_staff}
                                fullWidth
                                value={candidate.first_name}
                                onChange={(e) => setCandidate({ ...candidate, first_name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="last_name"
                                name="last_name"
                                disabled={!user.is_staff}
                                label="Nom"
                                fullWidth
                                value={candidate.last_name}
                                onChange={(e) => setCandidate({ ...candidate, last_name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Courriel"
                                fullWidth
                                value={candidate.email}
                                onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
                                disabled={!user.is_staff}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                languagesLoading ? "Chargement..." :

                                    <Autocomplete
                                        id="language"
                                        name="language"
                                        options={languages}
                                        value={languages.find((language) => language.id === candidate.language) || null}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Langue" />}
                                        onChange={(e, value) => setCandidate({ ...candidate, language: value.id })}
                                        disabled={!user.is_staff}
                                    />
                            }
                        </Grid>
                    </Grid>
                    <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>Informations de dossier</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {
                                dossiersLoading ? <Skeleton /> :

                                    <Autocomplete
                                        id="dossier"
                                        name="dossier"
                                        options={dossiers}
                                        value={dossiers.find((dossier) => dossier.id === candidate.dossier) || null}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Dossier" />}
                                        onChange={(e, value) => setCandidate({ ...candidate, dossier: value.id })}
                                        disabled={!user.is_staff}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={candidate.is_active}
                                        onChange={(e) => setCandidate({ ...candidate, is_active: e.target.checked })}
                                        name="is_active"
                                        color="primary"
                                        disabled={!user.is_staff}
                                    />
                                }
                                label="Actif"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={candidate.is_notified}
                                        onChange={(e) => setCandidate({ ...candidate, is_notified: e.target.checked })}
                                        name="is_notified"
                                        color="primary"
                                        disabled={!user.is_staff}
                                    />
                                }
                                label="Notifié"
                            />
                        </Grid>
                    </Grid>
                    {
                        user.is_staff &&
                        <Box sx={{ mt: 2, display: "flex" }} justifyContent={"flex-end"}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"

                            >
                                Mettre à jour
                            </Button>
                        </Box>
                    }
                </Paper>
            </Box>
            <Box component={"form"} onSubmit={handleSubmitPassword}>
                <Paper sx={{ p: 2, mt: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations de connexion</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                disabled
                                id="username"
                                name="username"
                                label="Nom d'utilisateur"
                                fullWidth
                                value={candidate.username}
                                onChange={(e) => setCandidate({ ...candidate, username: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                id="password"
                                name="password"
                                label="Mot de passe"
                                disabled={!user.is_staff}
                                fullWidth
                                value={candidate.password}
                                onChange={(e) => setCandidate({ ...candidate, password: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                id="password2"
                                name="password2"
                                label="Confirmer le mot de passe"
                                fullWidth
                                disabled={!user.is_staff}
                                value={candidate.password2}
                                onChange={(e) => setCandidate({ ...candidate, password2: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {
                        user.is_staff &&

                        <Box sx={{ mt: 2, display: "flex" }} justifyContent={"flex-end"}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"

                            >
                                Changer le mot de passe
                            </Button>
                        </Box>
                    }
                </Paper>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={response.error} sx={{ backgroundColor: "red" }} autoHideDuration={6000} onClose={() => response.reset()} message={response.error?.data} />
            <Snackbar open={response.isSuccess} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => response.reset()} autoHideDuration={6000} message={"Mise à jour réussi"} />

            <Snackbar open={responsePassword.isSuccess} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => responsePassword.reset()} autoHideDuration={6000} message={"Mot de passe mis à jour"} />

            <Snackbar open={responsePassword.error} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => responsePassword.reset()} autoHideDuration={6000} message={"Erreur lors de la mise à jour du mot de passe"} />
        </Page>
    )
}
export default CandidateDetails