import {rtkapi} from './rtkapi';

const cardapi = rtkapi.enhanceEndpoints({addTagTypes: ['Card']}).injectEndpoints({
   endpoints: (build) => ({
      getCard: build.query({
         query: (params) => ({ url: '/cards/', method: 'get', params }),
         providesTags: ['Card']
      }),
      
   }),
})

export const {
   useGetCardQuery,
} = cardapi; 
