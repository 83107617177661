import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, Autocomplete, FormControlLabel, Switch, Skeleton, Snackbar } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useGetDossiertypesQuery } from "../rtk/dossiertypes";
import { DatePicker } from "@mui/x-date-pickers";
import { useCreateDossierMutation, useGetDossierQuery, useUpdateDossierMutation } from "../rtk/dossiers";
import moment from "moment";
import Page from "../components/Page";
import { useGetClientsQuery } from "../rtk/clients";

const DossierAdd = (props) => {

    const navigate = useNavigate();
    const { data: dossierTypes, isLoading: dossierTypesLoading } = useGetDossiertypesQuery()
    const [createDossier, response] = useCreateDossierMutation();
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery()

    const [dossier, setDossier] = useState({
        name: "",
        type: null,
        start_date: null,
        end_date: null,
        is_active: true,
    });

    useEffect(() => {
        if (response.data) {
            navigate("/dossiers/" + response.data.id)
        }
    }, [response.data])


    const handleSubmit = (e) => {
        e.preventDefault();
        const start_date = dossier.start_date ? moment(dossier.start_date).format("YYYY-MM-DD") : null
        const end_date = dossier.end_date ? moment(dossier.end_date).format("YYYY-MM-DD") : null
        createDossier({ ...dossier, start_date: start_date, end_date: end_date });
    }


    return (
        <Page title={"Nouveau dossier"} needGoBack>
            <Box component={"form"} onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }} elevation={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Nom"
                                fullWidth
                                value={dossier.name}
                                onChange={(e) => setDossier({ ...dossier, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                dossierTypesLoading ? <Skeleton />
                                    :
                                    <Autocomplete
                                        id="type"
                                        name="type"
                                        options={dossierTypes}
                                        getOptionLabel={(option) => option.name}
                                        fullWidth
                                        value={dossierTypes.find((dt) => dt.id === dossier.type) || null}
                                        onChange={(e, value) => setDossier({ ...dossier, type: value.id })}
                                        renderInput={(params) => <TextField {...params} label="Type" />}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Date de début"
                                value={dossier.start_date ? moment(dossier.start_date) : null}
                                onChange={(value) => setDossier({ ...dossier, start_date: value })}
                                renderInput={(params) => <TextField {...params} />}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Date de fin"
                                value={dossier.end_date ? moment(dossier.end_date) : null }
                                onChange={(value) => setDossier({ ...dossier, end_date: value })}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                clientsLoading ? <Skeleton />
                                    :
                                    <Autocomplete
                                        id="client"
                                        name="client"
                                        options={clients.filter((c) => c.is_active)}
                                        getOptionLabel={(option) => option.name}
                                        fullWidth
                                        value={clients.find((c) => c.id === dossier.client) || null}
                                        onChange={(e, value) => setDossier({ ...dossier, client: value.id })}
                                        renderInput={(params) => <TextField {...params} label="Client" />}
                                    />

                            }
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Switch checked={dossier.is_active} onChange={(e) => setDossier({ ...dossier, is_active: e.target.checked })} />}
                                label="Actif"
                            />
                        </Grid> */}
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Créer
                        </Button>
                    </Stack>
                </Paper>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={response.error} sx={{ backgroundColor: "red" }} autoHideDuration={6000} onClose={() => response.reset()} message={JSON.stringify(response.error?.data)} />
            <Snackbar open={response.isSuccess} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => response.reset()} autoHideDuration={6000} message={"Mise à jour réussi"} />
        </Page>
    )
}
export default DossierAdd