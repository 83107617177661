import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, ThemeProvider } from '@mui/material';
import { useGetCardQuery } from "../rtk/cards";
import { useGetWordsQuery } from "../rtk/words";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import logo from "../img/logoCarte.jpg";


const StandardCard = (props) => {
    const { candidatId } = useParams()
    const { data: card, isLoading } = useGetCardQuery({ candidate: candidatId }, { skip: !candidatId })
    const { data: words, isLoading: wordIsLoading } = useGetWordsQuery()

    const getWordInLanguage = (word) => {
        if (!words) return ""
        const w = words.find((w) => w.id === word)
        return w.word_fr
    }

    const renderValueAndForces = () => {
        if (card) {
            return (
                <Stack sx={{ border: "1px solid black", height: "100%", justifyContent: "space-evenly" }}>
                    {
                        card.values.map((value, i) => {
                            return (
                                <Stack sx={{ borderTop: (i > 0 ? "1px solid black" : ""), pl: 0.3 }}>
                                    <Typography variant="h5" sx={{ color: "primary.main", fontWeight: "" }}>{getWordInLanguage(value.word)}</Typography>
                                    <Stack>
                                        {
                                            value.qualities.map((quality) => {
                                                return (
                                                    <>
                                                        {
                                                            quality.forces.map((force) => {
                                                                if (force.is_main_force) {
                                                                    return (
                                                                        <Typography sx={{ pl: 1.8 }} variant="body1">{getWordInLanguage(force.word)}</Typography>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            )
                        })
                    }

                </Stack>
            )
        }
    }

    const renderValues = () => {
        if (card) {
            return (
                <Box sx={{ height: "100%", border: "1px solid black",  alignItems:"center", display:"flex", flexDirection:"column" }} spacing={2}>
                    <Typography textAlign={"center"} variant="h2" sx={{ color: "primary.main", fontWeight: "bold", mt: 2, mb:3 }}>{card.name}</Typography>
                    <Stack alignItems={"center"} justifyContent={"center"} spacing={2} >
                        {
                            card.values.map((value) => {
                                return (
                                    <Typography textAlign={"center"} variant="h4" sx={{ fontWeight: "bold" }}>{getWordInLanguage(value.word)}</Typography>
                                )
                            })
                        }
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb:2, mt:"auto",mr:1.5 }}>
                        <img src={logo} alt="logo" style={{ width: "60%" }} />
                    </Box>

                </Box>
            )
        }
    }

    const renderForces = (indexes) => {
        if (card) {
            return (
                <Stack sx={{ height: "100%", border: "1px solid black" }} direction={"row"} justifyContent={"space-evenly"}>
                    {
                        card.values.map((value, i) => {
                            if (indexes.includes(i)) {
                                return (
                                    <Stack sx={{ borderLeft: (indexes.indexOf(i) > 0 ? "1px solid black" : ""), width: "100%" }} >
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid black", backgroundColor:"primary.main" }}>
                                            <Typography textAlign={"center"} variant="h5" sx={{ fontWeight: "bold", color:"#FFF" }}>{getWordInLanguage(value.word)}</Typography>
                                        </Box>
                                        <Stack sx={{justifyContent: "space-around", height:"100%"}}>
                                        {
                                            value.qualities.map((quality, index) => {
                                                return (
                                                    <Stack sx={{ borderTop: (index > 0 ? "1px solid black" : "") }}>
                                                        <Typography textAlign={"left"} variant="h6" sx={{ fontWeight: "bold", color: "primary.main", ml:0.2, mt:0.2 }}>{getWordInLanguage(quality.word)}</Typography>
                                                        {
                                                            quality.forces.map((force) => {
                                                                return (
                                                                    <Typography textAlign={"left"} sx={{ml:1.8}} variant="body2" >{getWordInLanguage(force.word)}</Typography>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </Stack>

                                                )
                                            })
                                        }
                                        </Stack>

                                    </Stack>
                                )
                            }
                        })
                    }
                </Stack>
            )
        }
    }

    const theme = useTheme()

    if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />
    }

    theme.typography.h1 = {
        fontSize: "28px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.h2 = {
        fontSize: "24px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.h3 = {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.h4 = {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.h5 = {
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.h6 = {
        fontSize: "10px",
        fontWeight: "bold",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.body1 = {
        fontSize: "9px",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }
    theme.typography.body2 = {
        fontSize: "8px",
        fontFamily: [
            'Roboto',
            'sans-serif',
          ].join(','),
    }

    // const renderSimpleCard = () => {
    //     if (card) {
    //         return (

    //         )
    //     }
    // }  

    return (
        <ThemeProvider theme={theme}>
            <Box  sx={{ p: 0,pl:0,pr:0, height: "158mm", width: "94mm", backgroundColor: "#ffffff", justifyContent:"center", margin:"auto", mt: 2, pb:0 }}>
                <Grid container spacing={"4mm"} >
                    <Grid item xs={6} height={"79mm"}>
                        {renderValueAndForces()}
                    </Grid>
                    <Grid item xs={6} height={"79mm"}>
                        {renderValues()}
                    </Grid>
                    <Grid item xs={6} height={"79mm"}>
                        {renderForces([0, 1])}
                    </Grid>
                    <Grid item xs={6} height={"79mm"}>
                        {renderForces([2, 3])}
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )

}
export default StandardCard