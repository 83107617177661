import React, { useState, useEffect, useRef } from "react";
import { InputAdornment, Paper } from "@mui/material";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from "react-router-dom";


const SearchBar = (props) => {

    let location = useLocation();


    return (<Paper elevation={10} className="search-bar">
        <div className="float-start">
            {
                !!!props.hidden &&
                <TextField
                    id="input-with-icon-textfield"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"
                                sx={{
                                    margin: 0
                                }}
                            >
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    inputProps={{
                        style: {
                            padding: 6
                        },
                    }}
                    value={props.searchValue}
                    onChange={props.searchOnChange}
                    placeholder="Rechercher" />
            }

        </div>
        <div style={{ marginLeft: "auto", display: "inline-flex", alignItems: "center" }}>
            {props.children}


        </div>

    </Paper>)
}
export default SearchBar