import { rtkapi } from "./rtkapi"

const meapi = rtkapi.enhanceEndpoints({addTagTypes: ["Me"]}).injectEndpoints({
    endpoints: (build) => ({
        getMe: build.query({
            query: (params=null) => ({ url: '/me/', method: 'get', params:params}),
            providesTags:['Me']
        }),
    })
})

export const { useGetMeQuery } = meapi
