import { rtkapi } from "./rtkapi"

const register = rtkapi.injectEndpoints({
    endpoints: (build) => ({
        register: build.mutation({
            query: (data) => ({url: '/register/', method:'post', data:data}),
            invalidatesTags: ['Team', 'Employee']
        }),
        
        verify: build.query({
            query: (params) => ({url: '/register/verify', method:'get', params:params}),
        }),

        changePassword: build.mutation({
            query: ({pk,...data}) => ({url: `/register/change-password/${pk}/`, method:'patch', data:data}),
        }),

    })
})

export const { useRegisterMutation, useVerifyQuery, useChangePasswordMutation } = register
