import * as React from 'react';
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import { Badge, ClickAwayListener, Menu, MenuItem, SwipeableDrawer } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const LinkNavItem = ({ link, activeUrl, navOpen }) => {
  return <ListItemButton
    component={Link} to={link.path}
    className={activeUrl.startsWith(link.path) ? "selected-nav" : ""}
    sx={{
      minHeight: 48,
      justifyContent: navOpen ? 'initial' : 'center',
      px: 2.5,
    }}
  >
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: navOpen ? 3 : 'auto',
        justifyContent: 'center',
      }}
    >
      {link.icon}
    </ListItemIcon>
    <ListItemText primary={link.text} sx={{ opacity: navOpen ? 1 : 0 }} />
  </ListItemButton>;
}

const MenuNavItem = ({ link, activeUrl, navOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <><ListItemButton
    onClick={handleClick}
    className={activeUrl.startsWith(link.path) ? "selected-nav" : ""}
    sx={{
      minHeight: 48,
      justifyContent: navOpen ? 'initial' : 'center',
      px: 2.5,
    }}
  >
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: navOpen ? 3 : 'auto',
        justifyContent: 'center',
      }}
    >
      {link.icon}
    </ListItemIcon>
    <ListItemText primary={link.text} sx={{ opacity: navOpen ? 1 : 0 }} />
  </ListItemButton>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}


      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,

          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 30,
            left: -5,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'left', vertical: 'center' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
    >
      {link.elements.map(el => (
        <MenuItem
          key={el.text}
          component={Link} to={el.path}
          onClick={handleClose}>
          <ListItemIcon>
            {el.icon}
          </ListItemIcon>
          {el.text}
        </MenuItem>
      ))}

    </Menu>
  </>
}

export const MobileDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation()
  const [activeUrl, setActiveUrl] = useState(location.pathname)

  const toggleDrawerOpen = () => {
    setOpen(!!!open);
  };

  useEffect(() => {
    setActiveUrl(location.pathname)
  }, [location])


  return (
    <>
      <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Box sx={{ display: 'flex', height: "100%" }}>
        {/* <AppBar position="fixed" open={open} sx={{backgroundColor:"var(--color-bg-high)", backgroundImage:"linear-gradient(#ffffff00,#ffffff00)"}}>
            <Toolbar variant='dense'>
            </Toolbar>
          </AppBar> */}

        <SwipeableDrawer anchor='left' variant="persistant" open={open} >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>
          {open ?
            <Box height={"6em"} sx={{ verticalAlign: "middle", display: "inline-flex" }} >
              <Typography margin={"auto"} textAlign={"center"}>{`${props.user?.first_name || ""} ${props.user?.last_name || ""}`}</Typography>
            </Box>
            :
            <Typography height={"6em"}></Typography>
          }
          <Divider />

          <List>
            {props.links.map((link, index) => (
              <ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
                {link.type === "link" ? <LinkNavItem link={link} activeUrl={activeUrl} navOpen={open} /> : <MenuNavItem link={link} activeUrl={activeUrl} navOpen={open} />}
              </ListItem>
            ))}
          </List>
          <List sx={{ marginTop: "auto" }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={props.logoff}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon color='error' />
                </ListItemIcon>
                <ListItemText primary={"Déconnexion"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <Box component="main" sx={{ flexGrow: 1,  height: "100%" }}>
          {props.children}
        </Box>
      </Box>
    </>
  );

}


const AppDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation()
  const [activeUrl, setActiveUrl] = useState(location.pathname)

  const toggleDrawerOpen = () => {
    setOpen(!!!open);
  };

  useEffect(() => {
    setActiveUrl(location.pathname)
  }, [location])


  return (

    <Box sx={{ display: 'flex', height: "100%" }}>
      {/* <AppBar position="fixed" open={open} sx={{backgroundColor:"var(--color-bg-high)", backgroundImage:"linear-gradient(#ffffff00,#ffffff00)"}}>
            <Toolbar variant='dense'>
            </Toolbar>
          </AppBar> */}
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Drawer variant="permanent" open={open} sx={{visibility:(props.hidden ? "hidden": "visible" )}} >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>
          {open ?
            <Box height={"6em"} sx={{ verticalAlign: "middle", display: "inline-flex" }} >
              <Typography margin={"auto"} textAlign={"center"}>{`${props.user?.first_name || ""} ${props.user?.last_name || ""}`}</Typography>
            </Box>
            :
            <Typography height={"6em"}></Typography>
          }
          <Divider />

          <List>
            {props.links.map((link, index) => (
              <ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
                {link.type === "link" ? <LinkNavItem link={link} activeUrl={activeUrl} navOpen={open} /> : <MenuNavItem link={link} activeUrl={activeUrl} navOpen={open} />}
              </ListItem>
            ))}
          </List>
          <List sx={{ marginTop: "auto" }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={props.logoff}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon color='error' />
                </ListItemIcon>
                <ListItemText primary={"Déconnexion"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
      <Box component="main" sx={{ flexGrow: 1, pl: 3, height: "100%" }}>
        {props.children}
      </Box>
    </Box>
  );

}

export default AppDrawer;