import React, { useState, useEffect, useRef } from "react";
import { useDeleteClientMutation, useUpdateClientMutation } from "../rtk/clients";
import { FormControlLabel, Snackbar, Switch } from "@mui/material";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider } from '@mui/material';

const ClientsDetails = (props) => {


    const [updateClient, response] = useUpdateClientMutation();
    const [data, setData] = useState(props.client);

    const handleUpdate = (e) => {
        e.preventDefault();
        updateClient(data);
    }

    useEffect(() => {
        if (response.isSuccess) {
            if (props.onSuccess) props.onSuccess()
            setData({ name: "" });
        }
        else {
            if (props.onError) props.onError()
        }
    }, [response])

    return (
        <Box onSubmit={handleUpdate} component={"form"} sx={{ ...props.sx }}>
            <Stack spacing={2} sx={{ marginTop: "1em" }}>
                <TextField
                    id="name"
                    label="Nom du client"
                    variant="outlined"
                    value={data.name}
                    required
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={data.is_active}
                            onChange={(e) => setData({ ...data, is_active: e.target.checked })}
                            name="is_active"
                            color="primary"
                        />
                    }
                    label="Actif"
                />
                <Button variant="contained" type="submit">Modifier</Button>
            </Stack>
            <Snackbar color="error" open={response.isError} message={response.error?.data && response.error?.data.length < 200 ? JSON.stringify(response.error.data) : "Erreur"} autoHideDuration={60} />
        </Box>
    )
}
export default ClientsDetails