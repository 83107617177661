import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Navigate, Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fr } from 'date-fns/locale';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import { isMobile } from 'react-device-detect';
import { MobileView } from "react-device-detect";
import AppDrawer, { MobileDrawer } from './components/AppDrawer';
import moment from 'moment';
import 'moment/locale/fr';
import SettingsPage from './Settings/SettingsPage';
import { Abc, AccountBox, AddModerator, Business, Email, Folder, Groups, SupervisorAccountRounded } from '@mui/icons-material';
import ClientsPage from './Clients/ClientsPage';
import CandidatesPage from './Candidates/CandidatesPage';
import CandidateAdd from './Candidates/CandidateAdd';
import CandidateDetails from './Candidates/CandidateDetails';
import DossierPage from './Dossiers/DossierPage';
import DossierDetails from './Dossiers/DossierDetails';
import DossierAdd from './Dossiers/DossierAdd';
import RepresentativePage from './Representatives/RepresentativePage';
import RepresentativeAdd from './Representatives/RepresentativeAdd';
import RepresentativeDetails from './Representatives/RepresentativeDetails';
import WordsPage from './Words/WordsPage';
import { useSelector } from 'react-redux';
import StandardCard from './Cards/StandardCard';
import SendEmailsView from './Email/SendEmailsView';
import SimpleCard from './Cards/SimpleCard';

moment.locale('fr')

const conditionnalBottomNavButton = (label, to, icon) => {
    return <BottomNavigationAction label={label} icon={icon} to={to} component={Link} />
}

const MainApp = (props) => {

    const user = useSelector((state) => state.user.user);
    // const bottomNavPath = []
    // const bottomNavItems = []

    const links = [
        { text: 'Clients', icon: <Business />, path: '/clients', type: 'link' },
        { text: 'Candidats', icon: <AccountBox />, path: '/candidates', type: 'link' },
        { text: 'Dossiers', icon: <Folder />, path: '/dossiers', type: 'link' },
        { text: 'Représentants', icon: <Groups />, path: '/representatives', type: 'link' },
        { text: 'Mots', icon: <Abc />, path: '/words', type: 'link' },
        { text: 'Emails', icon: <Email />, path: '/emails', type: 'link' },
    ]

    const repLinks = [
        { text: 'Candidats', icon: <AccountBox />, path: '/candidates', type: 'link' },
    ]
    // bottomNavPath.push('/lexicon')
    // bottomNavItems.push(conditionnalBottomNavButton("Glossaire", '/lexicon', <MenuBookIcon />))


    const [bottomNavValue, setBottomNavValue] = useState(links.map(el => el.path).indexOf(window.location.pathname));

    useEffect(() => {
        setBottomNavValue(links.map(el => el.path).indexOf(window.location.pathname))
    }, [links])


    function bottomnav() {
        return <BottomNavigation
            showLabels
            style={{ position: "fixed", zIndex: "1", width: "100%", bottom: 0, margin: "0 -8px", color: "white", boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.20)" }}
            value={bottomNavValue}
            onChange={(event, newValue) => {
                setBottomNavValue(newValue);
            }}
        >
            {links.map(
                el => conditionnalBottomNavButton(el.text, el.path, el.icon)
            )}
        </BottomNavigation>
    }


    function sidenav() {
        // verify is pathname is /standard-cards/:candidatId
        const path = window.location.pathname.split('/')
        return (<React.Fragment>
            <AppDrawer hidden={path[1] === 'cards'} links={getLinks()} user={user} logoff={props.logoff}>
                {getUrls()}
            </AppDrawer>
        </React.Fragment>)
    }

    const url = <LocalizationProvider adapterLocale={'fr'} dateAdapter={AdapterMoment}>
        <Routes>
            <Route path="/" element={<Navigate to="/clients" />}>
            </Route>
            <Route path="/clients" element={<ClientsPage />}/>
            <Route path="/candidates" element={<CandidatesPage />}/>
            <Route path="/candidates/add" element={<CandidateAdd />}/>
            <Route path="/candidates/:candidateId?" element={<CandidateDetails />}/>
            <Route path="/dossiers" element={<DossierPage />}/>
            <Route path="/dossiers/add" element={<DossierAdd />}/>
            <Route path="/dossiers/:dossierId" element={<DossierDetails />}/>
            <Route path="/representatives" element={<RepresentativePage />}/>
            <Route path="/representatives/add" element={<RepresentativeAdd />}/>
            <Route path="/representatives/:representativeId" element={<RepresentativeDetails />}/>
            <Route path="/cards/:candidatId/standard" element={<StandardCard/>}/>
            <Route path="/cards/:candidatId/simple" element={<SimpleCard/>}/>
            <Route path="/words" element={<WordsPage />}/>
            <Route path="/emails" element={<SendEmailsView />}/>

            <Route path="/settings" element={<SettingsPage />} />
            <Route path="*" element={<Navigate to="/candidates" />} />
        </Routes>
    </LocalizationProvider>;

    const repUrl = <LocalizationProvider adapterLocale={'fr'} dateAdapter={AdapterMoment}>
        <Routes>
            <Route path="/" element={<Navigate to="/candidates" />}>
            </Route>
            <Route path="/candidates" element={<CandidatesPage />}/>
            <Route path="*" element={<Navigate to="/candidates" />} />
            <Route path="/cards/:candidatId/standard" element={<StandardCard/>}/>
            <Route path="/cards/:candidatId/simple" element={<SimpleCard/>}/>
            <Route path="/candidates/:candidateId?" element={<CandidateDetails />}/>
        </Routes>
    </LocalizationProvider>;

    const getUrls = () => {
        if (user.role === 'representative') {
            return repUrl
        } else {
            return url
        }
    }

    const getLinks = () => {
        if (user.role === 'representative') {
            return repLinks
        } else {
            return links
        }
    }

    return (
        <Router>
            {!!!isMobile ?
                sidenav()
                :
                <MobileView className='full-height'>
                        <MobileDrawer links={getLinks()} user={user} logoff={props.logoff}>
                            {getUrls()}
                        </MobileDrawer>
                </MobileView>
            }
        </Router>
    )

}

export default MainApp;