import api from './api';
import './App.css';
import { Login } from './Login/Login.jsx';
import React, { useEffect, useState } from 'react';
import MainApp from './MainApp';
import { ThemeProvider } from '@emotion/react';
import theme from './styles/theme';


import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './rtk/store';
import { Button, Container, Paper, Typography, responsiveFontSizes } from '@mui/material';
import AuthenticationApp from './Login/AuthenticationApp';
import { setUser } from './rtk/userSlice';
import CandidateApp from './CandidateApp';
import { isMobile } from 'react-device-detect';
import { useGetMeQuery } from './rtk/me';

const App = (props) => {
  let [token, setToken] = useState(localStorage.getItem("accessToken"))
  let [isConnected, setIsConnected] = useState(null)

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const {data:me, refetch} = useGetMeQuery({}, {skip: !isConnected})

  useEffect(() => {
    verifyAuth()
  }, [])

  useEffect(() => {
    if (isConnected) {
      getIsAdmin()
    }
  }, [isConnected, me])

  useEffect(() => {
    if (isConnected) {
      refetch()
    }
  }, [isConnected])

  // verify is user is connected when user is interacting with the app
  useEffect(() => {
    const interval = setInterval(() => {
      verifyAuth()
    }, 1000 * 60 * 5 );
    return () => clearInterval(interval);
  }, []);



  const logoff = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    dispatch(setUser(null))
    setIsConnected(false)
  }


  const verifyAuth = async () => {
    let storedToken = localStorage.getItem("accessToken");

    if (storedToken) {
      let res = await api.post('/token/verify/', { 'token': storedToken })

      if (res.status === 200) {
        if (!token) {
          setToken(storedToken)
        }
        setIsConnected(true)
        // getIsAdmin()
        return
      }
    }
    setIsConnected(false)

  }

  const getIsAdmin = async () => {
    let res = await api.get('/me/')
    if (me) {
      dispatch(setUser(me))
    }
    else {
      dispatch(setUser(null))
    }
  }

  let theme2 = responsiveFontSizes(theme)

  theme2.typography.h1 = {
    fontSize: isMobile ? "1.5rem" : "2.5rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.24px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.h2 = {
    fontSize: isMobile ? "1.25rem" : "2rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.24px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),

  }
  theme2.typography.h3 = {
    fontSize: isMobile ? "1.125rem" : "1.75rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.06px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.h4 = {
    fontSize: isMobile ? "1rem" : "1.5rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.06px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.h5 = {
    fontSize: isMobile ? "0.875rem" : "1.25rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.05px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.h6 = {
    fontSize: isMobile ? "0.75rem" : "1.125rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.05px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.subtitle1 = {
    fontSize: isMobile ? "0.875rem" : "1rem",
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: "-0.05px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.subtitle2 = {
    fontSize: isMobile ? "0.75rem" : "0.875rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.04px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),

  }
  theme2.typography.body1 = {
    fontSize: isMobile ? "0.85rem" : "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.05px",
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
  theme2.typography.body2 = {
    fontSize: isMobile ? "0.70rem" : "0.95rem",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: "-0.04px",
        fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }




  if (isConnected && user && user?.role === "candidate") {
    return (
      <ThemeProvider theme={theme2}>
        <CandidateApp logoff={logoff} />
      </ThemeProvider>
    )
  }

  if (isConnected && user && (user?.is_staff || user?.role === "representative")) {
    return (
      <ThemeProvider theme={theme2}>
        <MainApp logoff={logoff} />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthenticationApp verifyAuth={verifyAuth} ></AuthenticationApp>
    </ThemeProvider>
  )
}

export default App;
