import {rtkapi} from './rtkapi';

const dossierapi = rtkapi.enhanceEndpoints({addTagTypes: ['Dossier']}).injectEndpoints({
   endpoints: (build) => ({
      getDossiers: build.query({
         query: (params) => ({ url: '/dossiers/', method: 'get', params }),
         providesTags: ['Dossier']
      }),
      getDossier: build.query({
         query: (id) => ({ url: `/dossiers/${id}/`, method: 'get' }),
         providesTags: ['Dossier']
      }),
      createDossier: build.mutation({
         query: (data) => ({ url: '/dossiers/', method: 'post', data:data }),
         invalidatesTags: ['Dossier']
      }),
      updateDossier: build.mutation({
         query: ({id, ...data}) => ({ url: `/dossiers/${id}/`, method: 'patch', data:data }),
         invalidatesTags: ['Dossier']
      }),
      deleteDossier: build.mutation({
         query: (id) => ({ url: `/dossiers/${id}/`, method: 'delete' }),
         invalidatesTags: ['Dossier']
      }),
      getMyDossier: build.query({
         query: () => ({ url: '/my-dossier/', method: 'get' }),
         providesTags: ['Dossier']
      }),
   }),
})

export const {
   useGetDossiersQuery,
   useGetDossierQuery,
   useCreateDossierMutation,
   useUpdateDossierMutation,
   useDeleteDossierMutation,
   useGetMyDossierQuery
} = dossierapi; 