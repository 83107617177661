import { rtkapi } from './rtkapi';

const wordscoreapi = rtkapi.enhanceEndpoints({ addTagTypes: ['Wordscore', 'Value', 'Quality', 'Force'] }).injectEndpoints({
   endpoints: (build) => ({
      getWordscores: build.query({
         query: (params) => ({ url: '/wordscores/', method: 'get', params }),
         providesTags: ['Wordscore']
      }),
      getWordscore: build.query({
         query: (id) => ({ url: `/wordscores/${id}/`, method: 'get' }),
         providesTags: ['Wordscore']
      }),
      getValues: build.query({
         query: (params) => ({ url: '/values/', method: 'get', params }),
         providesTags: ['Value']
      }),
      getQualities: build.query({
         query: (params) => ({ url: '/qualities/', method: 'get', params }),
         providesTags: ['Quality']
      }),
      getForces: build.query({
         query: (params) => ({ url: '/forces/', method: 'get', params }),
         providesTags: ['Force']
      }),
      submitFirstStep: build.mutation({
         query: (data) => ({ url: '/first-step/', method: 'post', data: data }),
         invalidatesTags: ['Wordscore', 'Candidate']
      }),
      submitSecondStep: build.mutation({
         query: (data) => ({ url: '/second-step/', method: 'post', data: data }),
         invalidatesTags: ['Wordscore', 'Candidate', 'Value']
      }),
      submitThirdStep: build.mutation({
         query: (data) => ({ url: '/third-step/', method: 'post', data: data }),
         invalidatesTags: ['Wordscore', 'Candidate', 'Value', 'Quality']
      }),
      submitFourthStep: build.mutation({
         query: (data) => ({ url: '/fourth-step/', method: 'post', data: data }),
         invalidatesTags: ['Wordscore', 'Candidate', 'Value', 'Quality', 'Force']
      }),
      submitFifthStep: build.mutation({
         query: (data) => ({ url: '/fifth-step/', method: 'post', data: data }),
         invalidatesTags: ['Wordscore', 'Candidate', 'Value', 'Quality', 'Force']
      }),
   }),
})

export const {
   useGetWordscoresQuery,
   useGetWordscoreQuery,
   useSubmitFirstStepMutation,
   useSubmitSecondStepMutation,
   useGetValuesQuery,
   useSubmitThirdStepMutation,
   useSubmitFourthStepMutation,
   useGetQualitiesQuery,
   useGetForcesQuery,
   useSubmitFifthStepMutation
} = wordscoreapi; 