import React, { useState, useEffect, useRef } from "react";
import { useGetWordsQuery } from "../rtk/words";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, ChevronRight, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useGetImportancesQuery } from "../rtk/importance";
import { useGetQualitiesQuery, useGetValuesQuery, useGetWordscoresQuery, useSubmitFirstStepMutation, useSubmitFourthStepMutation, useSubmitSecondStepMutation, useSubmitThirdStepMutation } from "../rtk/form";
import { isMobile } from "react-device-detect";

const ValueArray = (props) => {
    const { value, setResults, results, qualities, disabled, active, setActive } = props

    const { t } = useTranslation()
    const { data: words } = useGetWordsQuery();
    const user = useSelector((state) => state.user.user)

    const getWordInLanguage = (word) => {
        const wordInLanguage = words.find((w) => w.id === word)
        if (!wordInLanguage) return ("")
        if (user.language === "en") {
            return wordInLanguage.word_en
        } else if (user.language === "sp") {
            return wordInLanguage.word_sp
        }
        else {
            return wordInLanguage.word_fr
        }
    }

    const fontSize = (isMobile ? "8px" : "")

    const renderMobile = () => {
        return (
            <Paper sx={{ p: 1, mb: 2, fontSize, overflow: "auto" }} elevation={10} >
                <Stack>
                    <Button
                        sx={{ mb: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={setActive}
                    >
                        {getWordInLanguage(value.word)}
                    </Button>
                    <Grid container columns={10} spacing={1}  >
                        {qualities.map((quality) => {
                            const qualityResults = results.filter((result) => result.quality === quality.id)
                            const visible = active
                            const disabled = !!!active || qualityResults.filter(el => el.word !== "").length === 5
                            return (
                                <Grid container item key={quality.id} xs={"auto"} rowSpacing={1} direction={"column"} >
                                    <Grid item key={quality.id} >
                                        <Button disabled={disabled} sx={{ p: 1, height: "100%", width: "100%", visibility: (visible ? "visible" : "hidden") }} elevation={4} variant="contained" onClick={() => setResults(quality)} >
                                            <Typography sx={{ fontSize }} variant="body2">{getWordInLanguage(quality.word)}</Typography>
                                        </Button>
                                    </Grid>
                                    {
                                        qualityResults.map((result) => {
                                            return (
                                                <Grid item key={result.id} >
                                                    <Paper sx={{ p: 0.5, height: "2.5em", width: "8em" }} elevation={4}>
                                                        <Typography noWrap sx={{ fontSize, textOverflow:"ellipsis"  }} variant="body2">{getWordInLanguage(result.word)}</Typography>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>

                </Stack>
            </Paper >
        )
    }




    const renderDesktop = () => {
        return (
            <Paper sx={{ p: 1, mb: 2, fontSize, overflow: "auto", width: "66em" }} elevation={10} >
                <Stack>
                    <Button
                        sx={{ mb: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={setActive}
                    >
                        {getWordInLanguage(value.word)}
                    </Button>
                    <Grid container spacing={1}>
                        {qualities.map((quality) => {
                            const qualityResults = results.filter((result) => result.quality === quality.id)
                            const visible = active
                            const disabled = !!!active || qualityResults.filter(el => el.word !== "").length === 5
                            return (
                                <>
                                    <Grid item key={quality.id} xs={2} >
                                        <Button disabled={disabled} sx={{ p: 0.5, height: "100%", width: "100%", visibility: (visible ? "visible" : "hidden") }} elevation={4} variant="contained" onClick={() => setResults(quality)} >
                                            <Typography sx={{ fontSize }} variant="body2">{getWordInLanguage(quality.word)}</Typography>
                                        </Button>
                                    </Grid>
                                    {qualityResults.map((result) => {
                                        return (
                                            <Grid item key={result.id} xs={2}>
                                                <Paper sx={{ p: 0, height: "100%", justifyContent:"center", alignItems:"center", textJustify:"" }} elevation={4}>
                                                    <Typography textAlign={"center"}  variant="body2">{getWordInLanguage(result.word)}</Typography>
                                                </Paper>
                                            </Grid>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </Grid>
                </Stack>
            </Paper>
        )
    }
    return (
        <>
            {isMobile ? renderMobile() : renderDesktop()}
        </>
    )
}


const FourthStep = (props) => {

    const { t } = useTranslation()
    const user = useSelector((state) => state.user.user)

    const { data: values } = useGetValuesQuery();
    const { data: qualities } = useGetQualitiesQuery();
    const { data: scores } = useGetWordscoresQuery();
    const { data: words } = useGetWordsQuery();
    const [submitFourthStep] = useSubmitFourthStepMutation();

    const [isInstructions, setIsInstructions] = useState(true)
    const [results, setResults] = useState([])
    const [bestWords, setBestWords] = useState([])
    const [activeValue, setActiveValue] = useState(null)
    const [activeWord, setActiveWord] = useState(null)

    useEffect(() => {
        if (scores) {
            getBestWords()
        }
    }, [scores, values, qualities])

    useEffect(() => {
        if (values && qualities) {
            setEmptyResults()
        }
    }, [values, qualities])

    useEffect(() => {
        if (results && words && bestWords.length > 0) {
            setActiveWord(getActiveWord())
        }
    }, [results, words, bestWords])


    useEffect(() => {
        if (!!!isInstructions) {
            props.setIsRunning(true)
        }
        else {
            props.setIsRunning(false)
        }
    }, [isInstructions])


    const setEmptyResults = () => {
        if (!values || !qualities) return
        const defaultResults = []
        values.forEach((value) => {
            const valueQualities = qualities.filter((quality) => quality.value === value.id)
            valueQualities.forEach((quality) => {
                for (let i = 0; i < 5; i++) {
                    defaultResults.push({ value: value.id, quality: quality.id, word: "" })
                }

            })
        })
        setResults(defaultResults)
    }

    const getWordInLanguage = (word) => {
        const wordInLanguage = words.find((w) => w.id === word)
        if (!wordInLanguage) return ("")
        if (user.language === "en") {
            return wordInLanguage.word_en
        } else if (user.language === "sp") {
            return wordInLanguage.word_sp
        }
        else {
            return wordInLanguage.word_fr
        }
    }

    const getBestWords = () => {
        const categories = [6, 5, 4, 3, 2, 1, 0]
        // Get 80 best words (higher categories first) that are not already used as values or qualities

        if (!scores || !values || !qualities) return
        let temp_bestwords = []
        while (temp_bestwords.length < 80) {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i]
                const wordsInCategory = scores.filter((word) => word.importance === category)
                if (wordsInCategory.length > 0) {
                    temp_bestwords = [...temp_bestwords, ...wordsInCategory.map((word) => word.word)]
                    temp_bestwords = [...new Set(temp_bestwords)]
                    temp_bestwords = temp_bestwords.filter((word) => !values.map((value) => value.word).includes(word))
                    temp_bestwords = temp_bestwords.filter((word) => !qualities.map((quality) => quality.word).includes(word))
                }
            }
        }
        setBestWords(temp_bestwords)
    }

    const getActiveWord = () => {
        const possibleWords = bestWords.filter((word) => !results.map((result) => result.word).includes(word))
        return possibleWords[0]
    }


    const renderInstructions = () => {
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("fourth_step_title")}</Typography>
                <Typography variant="body1" textAlign={"justify"}>{t("fourth_step_instructions")}</Typography>
                <Typography variant="body1" textAlign={"justify"}>{t("stop_message")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant="outlined" onClick={props.logoff}>{t("stop")}</Button>
                    <Button variant="contained" onClick={() => setIsInstructions(false)} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>
            </Stack>
        )
    }

    const isStepCompleted = () => {
        return results.filter((result) => result.word === "").length === 0
    }

    const handleSubmit = () => {
        if (!isStepCompleted()) return
        submitFourthStep(results.map((result) => ({ quality: result.quality, word: result.word })))
    }

    const handleSetResult = (quality) => {
        const index = results.findIndex((result) => result.value === activeValue && result.quality === quality.id && result.word === "")
        const newResults = [...results]
        newResults[index].word = activeWord

        setResults(newResults)
        setActiveValue(null)
    }

    const renderStep = () => {
        return (
            <Stack alignItems={"center"} >
                <Box sx={{height:"4em"}}>
                {activeValue === null ?
                    <span >
                        <Typography component={"span"} variant="h4">{t("fourth_step_first_part")}</Typography>
                        <Typography component={"span"} variant="h4" fontWeight={"bold"} sx={{ color: "primary.main" }}>{getWordInLanguage(activeWord)}</Typography>
                        <Typography component={"span"} variant="h4">{t("fourth_step_second_part")}</Typography>
                    </span>
                    :
                    <span>
                        <Typography component={"span"} variant="h4">{t("fourth_step_third_part")}</Typography>
                        <Typography component={"span"} variant="h4" fontWeight={"bold"} sx={{ color: "primary.main" }}>{getWordInLanguage(activeWord)}.</Typography>
                    </span>
                }
                </Box>
                <Stack sx={{ display: "flex", pt: 1, pb: 1, justifyContent: "center", alignItems: "center", }} spacing={2} >
                    {
                        values.map((value) => {
                            const valueQualities = qualities.filter((quality) => quality.value === value.id)
                            const valueResults = results.filter((result) => result.value === value.id)
                            return (
                                <ValueArray key={value.id} value={value} qualities={valueQualities} results={valueResults} setResults={handleSetResult} setActive={() => setActiveValue(value.id)} active={activeValue === value.id} />
                            )
                        }
                        )
                    }
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" disabled={!isStepCompleted()} onClick={handleSubmit} endIcon={<ChevronRight />}>{t("next")}</Button>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack spacing={2}>
            {
                isInstructions ? renderInstructions() :
                    renderStep()

            }
        </Stack>

    )
}
export default FourthStep