import React, { useState, useEffect, useRef } from "react";
import Page from "./components/Page";
import { useSelector } from "react-redux";
import { Box, Button, Container, Paper, Skeleton, Stack, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";

import logo from "./img/logo_big.png";
import { useTranslation } from "react-i18next";
import { useGetCandidateQuery, useUpdateCandidateMutation } from "./rtk/candidates";
import FirstStep from "./CandidatForm/FirstStep";
import { ChevronRight } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import SecondStep from "./CandidatForm/SecondStep";
import ThirdStep from "./CandidatForm/ThirdStep";
import FourthStep from "./CandidatForm/FourthStep";
import FifthStep from "./CandidatForm/FifthStep";
import { useGetMyDossierQuery } from "./rtk/dossiers";
import SimpleForm from "./CandidatForm/SimpleForm";
import StandardForm from "./CandidatForm/StandardForm";


const CandidateApp = (props) => {
    const user = useSelector((state) => state.user.user);
    const theme = useTheme()
    const { t, i18n } = useTranslation()

    const [step, setStep] = useState(0)

    const { data: candidate, isLoading: isLoadingCandidate, isError } = useGetCandidateQuery(user.pk)
    const { data: dossier, isLoading: isLoadingDossier, isError: isErrorDossier } = useGetMyDossierQuery()
    const [updateCandidate, { data, error }] = useUpdateCandidateMutation()

    const [timer, setTimer] = useState(0)
    const [intervalState, setIntervalState] = useState(null)
    const [isRunning, setIsRunning] = useState(false)
    const [isSimple, setIsSimple] = useState(false)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (dossier) {
            setIsSimple(dossier.is_simple)
            setIsActive(dossier.can_update)
        }
    }, [dossier])


    if (isLoadingDossier || isLoadingCandidate || !dossier) {
        return <>
            <div
                style={{
                    overflowX: "shown",
                    overflowY: "auto",
                }}
            >
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={isMobile ? 20 : 60}
                />
                <Container
                    maxWidth="xl"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        mt: 4,

                    }}
                >
                    <Paper sx={{ p: 2, m: 1, width: "100%", height: "100%" }}>
                        <Stack>
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={isMobile ? 20 : 60}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={isMobile ? 20 : 60}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={isMobile ? 20 : 60}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={isMobile ? 20 : 60}
                            />
                        </Stack>
                    </Paper>
                </Container>
            </div>
        </>
    }

    if (!isActive) {
        return (
            <Page>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {t("candidateApp.notActive")}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.logoff()}
                    >
                        {t("candidateApp.logoff")}
                    </Button>
                </Box>
            </Page>
        )
    }
                        

    if (isSimple){
        return (
            <SimpleForm logoff={props.logoff} setIsRunning={setIsRunning} />
        )
    }
    else {
        return (
            <StandardForm logoff={props.logoff} setIsRunning={setIsRunning} />
        )
    }
}
export default CandidateApp