import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, Autocomplete, FormControlLabel, Skeleton, Snackbar, Switch } from '@mui/material';
import Page from "../components/Page";
import { useGetLanguagesQuery } from "../rtk/language";
import MuiPhoneNumber from "mui-phone-number";
import { useGetDossiersQuery } from "../rtk/dossiers";
import { useGetCandidateQuery, useUpdateCandidateMutation } from "../rtk/candidates";
import { useParams } from "react-router-dom";
import PasswordTextField from "../components/PasswordTextField";
import { useGetClientsQuery } from "../rtk/clients";
import { useGetRepresentativeQuery, useUpdateRepresentativeMutation } from "../rtk/representatives";
import { useChangePasswordMutation } from "../rtk/register";

const RepresentativeDetails = (props) => {

    const { representativeId } = useParams()

    const { data: languages, isLoading: languagesLoading } = useGetLanguagesQuery();
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery();
    const { data: remoteRepresentative, isLoading: remoteRepresentativeLoading } = useGetRepresentativeQuery(representativeId, { skip: !representativeId });

    const [updateRepresentative, response] = useUpdateRepresentativeMutation();
    const [changePassword, changePasswordResponse] = useChangePasswordMutation();

    const [representative, setRepresentative] = useState({
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        password2: "",
        client: null,
        is_active: true,
    });

    useEffect(() => {
        if (remoteRepresentative) {
            setRepresentative(remoteRepresentative)
        }
    }, [remoteRepresentative])

    const handleSubmit = (e) => {
        e.preventDefault();
        updateRepresentative({ id: representativeId, ...representative });
    }

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        changePassword({ pk: representativeId, password: representative.password, password2: representative.password2 });
    }

    return (
        <Page title={representative.first_name + " " + representative.last_name} needGoBack={"/representatives/"}>
            <Box component={"form"} onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations personnelles</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="first_name"
                                name="first_name"
                                label="Prénom"
                                fullWidth
                                value={representative.first_name}
                                onChange={(e) => setRepresentative({ ...representative, first_name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="last_name"
                                name="last_name"
                                label="Nom"
                                fullWidth
                                value={representative.last_name}
                                onChange={(e) => setRepresentative({ ...representative, last_name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Courriel"
                                fullWidth
                                value={representative.email}
                                onChange={(e) => setRepresentative({ ...representative, email: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                !!!clients ?
                                    <Skeleton variant="rectangular" width={"100%"} height={55} />
                                    :
                                    <Autocomplete
                                        id="client"
                                        options={clients?.filter((client) => client.is_active)}
                                        loading={clientsLoading}
                                        getOptionLabel={(option) => option.name}
                                        value={clients.find((client) => client.id === representative.client) || null}
                                        onChange={(e, value) => setRepresentative({ ...representative, client: value.id })}
                                        renderInput={(params) => <TextField {...params} label="Client" />}
                                    />
                            }
                        </Grid>

                    </Grid>
                    <Box sx={{ mt: 2, display: "flex" }} justifyContent={"flex-end"}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"

                        >
                            Mettre à jour
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Box component={"form"} onSubmit={handleSubmitPassword}>
                <Paper sx={{ p: 2, mt: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations de connexion</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                id="username"
                                name="username"
                                label="Nom d'utilisateur"
                                fullWidth
                                disabled
                                value={representative.username}
                                onChange={(e) => setRepresentative({ ...representative, username: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                id="password"
                                name="password"
                                label="Mot de passe"
                                fullWidth
                                value={representative.password}
                                onChange={(e) => setRepresentative({ ...representative, password: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                id="password2"
                                name="password2"
                                label="Confirmer le mot de passe"
                                fullWidth
                                value={representative.password2}
                                onChange={(e) => setRepresentative({ ...representative, password2: e.target.value })}
                            />
                        </Grid>

                    </Grid>
                    <Box sx={{ mt: 2, display: "flex" }} justifyContent={"flex-end"}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"

                        >
                            Mettre à jour
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={response.error} sx={{ backgroundColor: "red" }} autoHideDuration={6000} onClose={() => response.reset()} message={response.error?.data} />
            <Snackbar open={response.isSuccess} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => response.reset()} autoHideDuration={6000} message={"Mise à jour réussi"} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={changePasswordResponse.error} sx={{ backgroundColor: "red" }} autoHideDuration={6000} onClose={() => changePasswordResponse.reset()} message={changePasswordResponse.error?.data} />
            <Snackbar open={changePasswordResponse.isSuccess} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => changePasswordResponse.reset()} autoHideDuration={6000} message={"Mise à jour réussi"} />
        </Page>
    )
}
export default RepresentativeDetails