import {rtkapi} from './rtkapi';

const representativeapi = rtkapi.enhanceEndpoints({addTagTypes: ['Representative']}).injectEndpoints({
   endpoints: (build) => ({
      getRepresentatives: build.query({
         query: (params) => ({ url: '/representatives/', method: 'get', params }),
         providesTags: ['Representative']
      }),
      getRepresentative: build.query({
         query: (id) => ({ url: `/representatives/${id}/`, method: 'get' }),
         providesTags: ['Representative']
      }),
      createRepresentative: build.mutation({
         query: (data) => ({ url: '/representatives/', method: 'post', data:data }),
         invalidatesTags: ['Representative']
      }),
      updateRepresentative: build.mutation({
         query: ({id, ...data}) => ({ url: `/representatives/${id}/`, method: 'patch', data:data }),
         invalidatesTags: ['Representative']
      }),
      deleteRepresentative: build.mutation({
         query: (id) => ({ url: `/representatives/${id}/`, method: 'delete' }),
         invalidatesTags: ['Representative']
      }),
   }),
})

export const {
   useGetRepresentativesQuery,
   useGetRepresentativeQuery,
   useCreateRepresentativeMutation,
   useUpdateRepresentativeMutation,
   useDeleteRepresentativeMutation
} = representativeapi; 
