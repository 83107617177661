import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, Autocomplete, FormControlLabel, Skeleton, Snackbar } from '@mui/material';
import Page from "../components/Page";
import { useGetLanguagesQuery } from "../rtk/language";
import MuiPhoneNumber from "mui-phone-number";
import { useGetDossiersQuery } from "../rtk/dossiers";
import { useCreateCandidateMutation } from "../rtk/candidates";
import { useNavigate } from "react-router-dom";
import PasswordTextField from "../components/PasswordTextField";
import { useCreateRepresentativeMutation } from "../rtk/representatives";
import { useGetClientsQuery } from "../rtk/clients";

const RepresentativeAdd = (props) => {
    const navigate = useNavigate()

    const [createRepresentative, response] = useCreateRepresentativeMutation();
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery({is_active: true});

    useEffect(() => {
        if (response.data) {
            navigate(`/representatives/${response.data.pk}`)
        }
    }, [response.data])

    const [representative, setRepresentative] = useState({
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        password2: "",
        client: null,
        is_active: true,
    });

    const getUserReadableError = (error) => {
        if (!error) {
            return ""
        }
        if (error.includes("username")) {
            return "Le nom d'utilisateur est déjà utilisé"
        }
        if (error.includes("email")) {
            return "Le courriel est déjà utilisé"
        }
        return error
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createRepresentative(representative);
    }


    const normalizeStr = (str) => {
        // normalize string to remove accents and whitespace
        return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "")
    }

    const representativeOnChange = (e) => {

        if (e.target.name == "last_name" || e.target.name == "first_name") {
            const oldUsername = normalizeStr(representative.first_name + "." + representative.last_name)
            let newUsername = (e.target.name === "first_name" ? normalizeStr(e.target.value) : normalizeStr(representative.first_name)) + "." + (e.target.name === "last_name" ? normalizeStr(e.target.value) : normalizeStr(representative.last_name))
            if (representative.username === oldUsername || representative.username === "") {
                setRepresentative({ ...representative, [e.target.name]: e.target.value, "username": newUsername })
                return
            }
        }

        setRepresentative({ ...representative, [e.target.name]: e.target.value })

    }

    const generatePassword = () => {
        const length = 6;
        const voyels = "aeiouyAEIOUY";
        const consonants = "bcdfghjklmnpqrstvwxzBCDFGHJKLMNPQRSTVWXZ";
        // const special = "!@#$%^&*()_+~`|}{[]:;?><,./-="
        let retVal = "";
        // alternate between consonants and voyels
        for (let i = 0; i < length; i++) {
            if (i % 2 === 0) {
                retVal += consonants.charAt(Math.floor(Math.random() * consonants.length));
            } else {
                retVal += voyels.charAt(Math.floor(Math.random() * voyels.length));
            }
        }
        //add a number
        retVal += Math.floor(Math.random() * 10);
        retVal += Math.floor(Math.random() * 10);
        retVal += Math.floor(Math.random() * 10);

        //add a special character
        // retVal += special.charAt(Math.floor(Math.random() * special.length))

        setRepresentative({ ...representative, "password": retVal, "password2": retVal })
    }


    return (
        <Page title={"Nouveau représentant"} needGoBack>
            <Box component={"form"} onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations personnelles</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="first_name"
                                name="first_name"
                                label="Prénom"
                                fullWidth
                                value={representative.first_name}
                                onChange={representativeOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="last_name"
                                name="last_name"
                                label="Nom"
                                fullWidth
                                value={representative.last_name}
                                onChange={representativeOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Courriel"
                                fullWidth
                                value={representative.email}
                                onChange={representativeOnChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                id="client"
                                options={clients}
                                loading={clientsLoading}
                                getOptionLabel={(option) => option.name}
                                value={clients?.find((client) => client.id === representative.client)}
                                onChange={(e, value) => setRepresentative({ ...representative, client: value.id })}
                                renderInput={(params) => <TextField {...params} label="Client" />}
                            />

                    </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{ p: 2, mt: 2 }} elevation={9}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Informations de connexion</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                id="username"
                                name="username"
                                label="Nom d'utilisateur"
                                fullWidth
                                value={representative.username}
                                onChange={(e) => setRepresentative({ ...representative, username: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                required
                                id="password"
                                name="password"
                                label="Mot de passe"
                                fullWidth
                                value={representative.password}
                                onChange={(e) => setRepresentative({ ...representative, password: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PasswordTextField
                                required
                                id="password2"
                                name="password2"
                                label="Confirmer le mot de passe"
                                fullWidth
                                value={representative.password2}
                                onChange={(e) => setRepresentative({ ...representative, password2: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={1} container justifyContent={"center"}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={generatePassword}
                            >
                                Générer
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Box sx={{ mt: 2, display: "flex" }} justifyContent={"flex-end"}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Ajouter
                    </Button>
                </Box>
            </Box>
            <Snackbar open={response.error} autoHideDuration={6000} message={getUserReadableError(JSON.stringify(response.error?.data))} />
        </Page>
    )
}
export default RepresentativeAdd