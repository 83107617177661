// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import axios from 'axios'

const baseUrl = "/api";


//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
      config.headers["CUUID"] = sessionStorage.getItem("CUUID");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    
    let refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken && error.response.status === 401 && !originalRequest._retry && (originalRequest.url != "/api/token/refresh/" )) 
    {
      originalRequest._retry = true;

      return axios.post(`${baseUrl}/token/refresh/`, { refresh: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access);
            localStorage.setItem("refreshToken", res.data.refresh);
            return axios(originalRequest);
          }
          else {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.reload();
            return Promise.reject(error);
          }
        });
    }
    if(originalRequest.url == "/api/token/refresh") {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      window.location.reload();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params })
      if (axios.isAxiosError(result)){
        return {
          error: {
            status: result.response?.status,
            data: result.response?.data || result.message,
          },
        }
      }
      return {data: result.data}
    } catch (AxiosError) {
      let err = AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

  // Define a service using a base URL and expected endpoints
export const rtkapi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: '/api',
    }),
    endpoints: () => ({})
})