import { rtkapi } from "./rtkapi"

const candidateapi = rtkapi.enhanceEndpoints({addTagTypes: ["Candidate"]}).injectEndpoints({
    endpoints: (build) => ({
        getCandidates: build.query({
            query: (params=null) => ({ url: '/candidates/', method: 'get', params:params}),
            providesTags:['Candidate']
        }),

        getCandidate: build.query({
            query: (id) => ({ url: `/candidates/${id}/`, method: 'get'}),
            providesTags:['Candidate']
        }),

        createCandidate: build.mutation({
            query: (data) => ({url: '/candidates/', method:'post', data:data}),
            invalidatesTags:['Candidate']

        }),

        updateCandidate: build.mutation({
            query: ({id,...data}) => ({url: `/candidates/${id}/`, method:'patch', data:data}),
            invalidatesTags:['Candidate']

        }), 

        deleteCandidate: build.mutation({
            query: (id) => ({url: `/candidates/${id}/`, method:'delete'}),
            invalidatesTags:['Candidate']
        }),

        sendEmails: build.mutation({
            query: (data) => ({url: '/send-mass-email/', method:'post', data:data}),
            invalidatesTags:['Candidate']
        }),
        
    })
})

export const { useGetCandidatesQuery, useCreateCandidateMutation, useUpdateCandidateMutation, useBatchImportMutation, useGetCandidateQuery, useDeleteCandidateMutation, useSendEmailsMutation } = candidateapi
