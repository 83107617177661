import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider } from '@mui/material';
import Page from '../components/Page.jsx';
import CandidatesList from "./CandidatesList.jsx";

const CandidatesPage = (props) => {
    
    return(
    <Page title={"Candidats"} maxWidth="lg" fixed={true}>
        <CandidatesList />
    </Page>
    )
}
export default CandidatesPage