import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        fr: {
            translation: {
                "salutation" : "Bonjour",
                "welcome_text" : "Vous allez remplir un questionnaire qui vous aidera à identifier vos valeurs fondamentales. Il se complète en plusieurs étapes faciles qui vous seront expliquées au cours du processus.Vous aurez peut-être l'impression que certaines étapes sont répétitives, ne vous en faites pas et soyez patient(e). Vous comprendrez le sens lorsque nous vous donnerons les résultats. Gardez le sourire!",
                "signature" : "L'équipe de Cavale",
                "continue": "Continuer",
                "remainingTime": "Temps restant",
                "welcome": "Accueil",
                "words": "Les mots",
                "values": "Les valeurs",
                "qualities": "Les qualités",
                "forces": "Les forces",
                "cards": "Cartes d'accès",
                "start": "Démarrer",
                "first_step_title": "Première étape: Les mots",
                "first_step_text_first_part": "Vous devez, dans cette première étape, choisir le niveau d'importance que représentent des mots dans votre vie de tous les jours. Il y a 211 mots en tout, alors, répondez aux questions de façon instinctive, sans trop y penser, à un rythme confortable.",
                "first_step_text_first_part_simple": "Vous devez, dans cette première étape, choisir le niveau d'importance que représentent des mots dans votre vie de tous les jours. Il y a 100 mots en tout, alors, répondez aux questions de façon instinctive, sans trop y penser, à un rythme confortable.",
                "first_step_text_second_part": " Vous aurez une limite de 25 minutes pour compléter cette partie du questionnaire. Si vous  dépassez cette limite, vos réponses seront automatiquement effacées et vous devrez  recommencer au début. Nous vous suggérons donc de prévoir une période de  tranquillité pour y participer sans interruptions.",
                "first_step_text_second_part_simple": " Vous aurez une limite de 15 minutes pour compléter cette partie du questionnaire. Si vous  dépassez cette limite, vos réponses seront automatiquement effacées et vous devrez  recommencer au début. Nous vous suggérons donc de prévoir une période de  tranquillité pour y participer sans interruptions.",
                "first_step_recap_instruction_1": "Spontanément",
                "first_step_recap_instruction_2": "Assignez le niveau d'importance que vous accordez à chacun des mots de la colonne de gauche en cliquant sur votre choix dans la colonne de droite.",
                "first_step_recap_instruction_3": "Si vous voulez modifier votre choix, glisser la nouvelle importance que vous accordez au mot par-dessus celle que vous aviez choisie",
                "not_important": "Pas important",
                "rarely_important": "Rarement important",
                "sometimes_important": "Important à l'occasion",
                "important": "Moyennement important",
                "often_important": "Souvent Important",
                "very_often_important": "Très souvent important",
                "always_important": "Toujours important",
                "importances": "Importances",
                "next": "Suivant",
                "second_step_title": "Deuxième étape: Vos valeurs fondamentales",
                "second_step_text": "Bravo, vous avez complété la première étape! Vous avez maintenant le choix de démarrer la deuxième qui prend généralement 5 minutes à compléter ou vous pouvez arrêter et y revenir plus tard. ",
                "second_step_instructions": "Parmi les mots suivants, choisir les 4 mots qui sont les plus importants pour vous. Tous les mots que vous ne choisirez pas feront tout de même partie de votre cartographie personnelle de valeurs et de forces.",
                "second_step_recap": "Voici vos 4 valeurs fondamentales.",
                "second_step_recap_2": "Elles serviront à compléter les étapes suivantes.",
                "stop_message": "Vous avez maintenant le choix de démarrer cette étape ou d'arrêter et d'y revenir plus tard.",
                "stop": "Arrêter",
                "third_step_title": "Troisième étape: Les qualités",
                "third_step_text": "Vous devez maintenant associer des mots qui qualifient le mieux le sens que vous accordez à chacune des 4 valeurs fondamentales que vous avez choisies dans la deuxième étape. Vous aurez un maximum de 20 minutes pour compléter cette étape.",
                "third_step_recap_instruction": "Choisir 4 qualités par valeur, qualités qui supportent le mieux le sens que vous accordez à celle-ci.",
                "third_step_for_your_value": "Pour votre valeur",
                "third_step_text_simple": "Vous devez maintenant associer des mots qui qualifient le mieux le sens que vous accordez à chacune des 4 valeurs fondamentales que vous avez choisies dans la deuxième étape. Vous aurez un maximum de 20 minutes pour compléter cette étape.",
                "third_step_recap_instruction_simple_1": "Choisir 3 qualités par valeur, qualités qui supportent le mieux le sens que vous accordez à celle-ci.",
                "third_step_recap_instruction_simple_2": "Choisir 2 qualités supplémentaires par valeur, qualités qui supportent le mieux le sens que vous accordez à celle-ci.",
                "quality": "Qualité",
                "fourth_step_title": "Quatrième étape: Les forces",
                "fourth_step_instructions": "C'est l'avant-dernière étape. Vous devez associer chaque mot qui apparaît au haut de l'écran à l'une de vos valeurs fondamentales et ensuite à une des qualités qui la supportent. N'oubliez pas de choisir de façon instinctive. À la fin, vous aurez remplis toutes les 80 cases du quadrilatère. Vous aurez 30 minutes pour compléter cette étape.",
                "fourth_step_first_part": "Pour le mot ",
                "fourth_step_second_part": ", choisir la valeur fondamentale qui lui correspond le mieux.",
                "fourth_step_third_part": "Maintenant, choisir la qualité correspond le mieux au mot ",
                "fifth_step_title": "Cinquième étape: Les mots d'accès",
                "fifth_step_instructions": "Vous êtes à la dernière étape du questionnaire. On vous demande de choisir parmi les mots que vous avez déjà choisis et qui sont placés à droite, lesquels vous semblent représenter le mieux la valeur fondamentale inscrite à gauche. Vous allez d'abord choisir un mot parmi 20 et ensuite un mot par ligne. Vous aurez 10 minutes pour compléter cette étape.",
                "fifth_step_instructions_mobile": "Vous êtes à la dernière étape du questionnaire. On vous demande de simplement choisir parmi les mots en bas que vous avez déjà choisis, lesquels vous semblent représenter le mieux la valeur fondamentale inscrite en haut. Vous allez d'abord choisir un mot parmi 20 et ensuite un mot par colonne. Vous aurez 10 minutes pour compléter cette étape.",
                "fifth_step_recap_instruction": "Choisir parmi les mots à droite lesquels vous semblent représenter le mieux la valeur fondamentale inscrite à gauche. Vous allez d'abord choisir un mot parmi 20 et ensuite un mot par ligne.  ",
                "fifth_step_first_part": "Choisir le mot qui représente le mieux la valeur fondamentale ",
                "final_step_title": "Merci",
                "final_step_text": "Vous avez terminé le questionnaire. Nous espérons que tout s'est bien déroulé pour vous. Nous serons heureux de vous communiquer les résultats bientôt.",
                "final_step_goodbye": "Au revoir et merci!",
                "completed": "Terminer",
                "candidateApp.logoff": "Déconnexion",
                "candidateApp.notActive": "Le dossier n'est plus actif",
            }
        },
        en: {
            translation: {
                "salutation" : "Hello",
                "welcome_text" : "You are going to fill out a questionnaire that will help you identify your core values. It is completed in several easy steps that will be explained to you during the process. You may feel that some steps are repetitive, don't worry and be patient, you will understand the meaning when you see the results.",
                "signature": "The Cavale team",
                "continue": "Continue",
                "remainingTime": "Remaining time",
                "welcome": "Welcome",
                "words": "Words",
                "values": "Values",
                "qualities": "Qualities",
                "forces": "Forces",
                "cards": "Access cards",
                "start": "Start",
                "first_step_title": "First step: The words",
                "first_step_text_first_part": "In section 1, you must select the level of importance you attribute to each word, in your everyday life. There are 211 words in all so please answer the questions intuitively without putting too much thought into it yet, at a comfortable pace.",
                "first_step_text_first_part_simple": "In section 1, you must select the level of importance you attribute to each word, in your everyday life. There are 100 words in all so please answer the questions intuitively without putting too much thought into it yet, at a comfortable pace.",
                "first_step_text_second_part": " You have a time limit of 25 minutes to complete this part of the questionnaire. If you exceed this time limit, your answers will automatically be erased and you will have to start the process again from the beginning.It is advisable to foresee a quiet period of time to complete this section without any interruptions.",
                "first_step_text_second_part_simple": " You have a time limit of 15 minutes to complete this part of the questionnaire. If you exceed this time limit, your answers will automatically be erased and you will have to start the process again from the beginning.It is advisable to foresee a quiet period of time to complete this section without any interruptions.",
                "first_step_recap_instruction_1": "Spontaneously",
                "first_step_recap_instruction_2": "Assign the level of importance you attribute to each word in the left column by clicking on your choice in the right column.",
                "first_step_recap_instruction_3": "You can change your choice by dragging the new importance level over the old one.",
                "not_important": "Not important",
                "rarely_important": "Rarely important",
                "sometimes_important": "Occasionnaly important",
                "important": "Moderately important",
                "often_important": "Often important",
                "very_often_important": "Very often important",
                "always_important": "Always important",
                "importances": "Importances",
                "next": "Next",
                "second_step_title": "Second step: Your core values",
                "second_step_text": "Congratulations, you have completed the first step! You now have the choice to start the second which usually takes 5 minutes to complete or you can stop and come back to it later.",
                "second_step_instructions": "Among the following words, choose the 4 words that are most important to you. All the words you do not choose will still be part of your personal mapping of values and strengths.",
                "second_step_recap": "Here are your 4 core values.",
                "second_step_recap_2": "They will be used in the next steps.",
                "stop_message": "You can also stop and come back later.",
                "stop": "Stop",
                "third_step_title": "Third step: The qualities",
                "third_step_text": "You must now associate words that best describe the meaning you attribute to each of the 4 core values you have chosen in the second step. You will have a maximum of 20 minutes to complete this step.",
                "third_step_for_your_value": "For your value",
                "third_step_recap_instruction": "Choose 4 qualities per value, which best support the meaning you attribute to it.",
                "third_step_text_simple": "You must now associate words that best describe the meaning you attribute to each of the 4 core values you have chosen in the second step. You will have a maximum of 30 minutes to complete this step.",
                "third_step_recap_instruction_simple_1": "Choose 3 qualities per value, which best support the meaning you attribute to it.",
                "third_step_recap_instruction_simple_2": "Choose 2 additional qualities per value, which best support the meaning you attribute to it.",
                "quality": "Quality",
                "fourth_step_title": "Fourth step: The strengths",
                "fourth_step_instructions": "This is the second to last step. You must associate each word that appears at the top of the screen with one of your core values and then with one of the qualities that support it. At the end, you will have filled in all 80 boxes of the quadrilateral. You will have 30 minutes to complete this step.",
                "fourth_step_first_part": "For the word ",
                "fourth_step_second_part": ", choose the core value that best corresponds to it.",
                "fourth_step_third_part": "Now, choose the quality that best corresponds to the word ",
                "fifth_step_title": "Fifth step: The access words",
                "fifth_step_instructions": "You are at the last step of the questionnaire. You are asked to simply choose from the words on the right that you have already chosen, which ones seem to you to best represent the core value written on the left. You will first choose one word out of 20 and then one word per line. You will have 10 minutes to complete this step.",
                "fifth_step_first_part": "Choose the word that best represents the core value ",
                "fifth_step_instructions_mobile": "You are at the last step of the questionnaire. You are asked to simply choose from the words at the bottom that you have already chosen, which ones seem to you to best represent the core value written at the top. You will first choose one word out of 20 and then one word per column. You will have 10 minutes to complete this step.",
                "fifth_step_recap_instruction": "Choose from the words on the right which ones you think best represent the fundamental value listed on the left. You will first choose one word from 20 and then one word per line.",
                "final_step_title": "Thank you",
                "final_step_text": "You have completed the questionnaire. We hope everything went well for you. During a meeting with the Cavale team, we will give you the results of the questionnaire.",
                "final_step_goodbye": "Goodbye and thank you!",
                "completed": "Completed",
                "candidateApp.logoff": "Logoff",
                "candidateApp.notActive": "The file is no longer active",

            }
        }
    },
});

export default i18n;
