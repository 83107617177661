import {rtkapi} from './rtkapi';

const wordapi = rtkapi.enhanceEndpoints({addTagTypes: ['Word']}).injectEndpoints({
   endpoints: (build) => ({
      getWords: build.query({
         query: (params) => ({ url: '/words/', method: 'get', params }),
         providesTags: ['Word']
      }),
      getWord: build.query({
         query: (id) => ({ url: `/words/${id}/`, method: 'get' }),
         providesTags: ['Word']
      }),
      createWord: build.mutation({
         query: (data) => ({ url: '/words/', method: 'post', data:data }),
         invalidatesTags: ['Word']
      }),
      updateWord: build.mutation({
         query: ({id, ...data}) => ({ url: `/words/${id}/`, method: 'patch', data:data }),
         invalidatesTags: ['Word']
      }),
      deleteWord: build.mutation({
         query: (id) => ({ url: `/words/${id}/`, method: 'delete' }),
         invalidatesTags: ['Word']
      }),
   }),
})

export const {
   useGetWordsQuery,
   useGetWordQuery,
   useCreateWordMutation,
   useUpdateWordMutation,
   useDeleteWordMutation
} = wordapi; 
