import {rtkapi} from './rtkapi';

const dossiertypeapi = rtkapi.enhanceEndpoints({addTagTypes: ['Dossiertype']}).injectEndpoints({
   endpoints: (build) => ({
      getDossiertypes: build.query({
         query: (params) => ({ url: '/dossier-types/', method: 'get', params }),
         providesTags: ['Dossiertype']
      }),
      getDossiertype: build.query({
         query: (id) => ({ url: `/dossier-types/${id}/`, method: 'get' }),
         providesTags: ['Dossiertype']
      }),
      createDossiertype: build.mutation({
         query: (data) => ({ url: '/dossier-types/', method: 'post', data:data }),
         invalidatesTags: ['Dossiertype']
      }),
      updateDossiertype: build.mutation({
         query: ({id, ...data}) => ({ url: `/dossier-types/${id}/`, method: 'patch', data:data }),
         invalidatesTags: ['Dossiertype']
      }),
      deleteDossiertype: build.mutation({
         query: (id) => ({ url: `/dossier-types/${id}/`, method: 'delete' }),
         invalidatesTags: ['Dossiertype']
      }),
   }),
})

export const {
   useGetDossiertypesQuery,
   useGetDossiertypeQuery,
   useCreateDossiertypeMutation,
   useUpdateDossiertypeMutation,
   useDeleteDossiertypeMutation
} = dossiertypeapi; 