import {rtkapi} from './rtkapi';

const importanceapi = rtkapi.enhanceEndpoints({addTagTypes: ['Importance']}).injectEndpoints({
   endpoints: (build) => ({
      getImportances: build.query({
         query: (params) => ({ url: '/importances/', method: 'get', params }),
         providesTags: ['Importance']
      }),
   }),
})

export const {
   useGetImportancesQuery,
} = importanceapi; 