import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, List, Skeleton, ListItem, ListItemButton, IconButton, Dialog, DialogTitle, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useGetClientsQuery } from "../rtk/clients";
import { useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import SearchBar from "../components/SearchBar.jsx";
import ClientsAdd from "./ClientsAdd";
import ClientsDetails from "./ClientsDetails";
import { searchIsValid } from "../utils";

const ClientsList = (props) => {
    const navigate = useNavigate();

    const { data: clients, isLoading } = useGetClientsQuery({}, { skip: props.clients });
    const [search, setSearch] = useState("");

    const usedClients = props.clients ? props.clients : clients;

    const [createOpen, setCreateOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(null);
    const [activeOnly, setActiveOnly] = useState(true);

    if (isLoading && !usedClients) {
        return (
            <Box sx={{ ...props.sx }}>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </Box>
        )
    }

    const filterFunction = (client) => {
        return (
            (!activeOnly || client.is_active) &&
            searchIsValid(client.name, search)
        )
    }

    const handleCreateDialogClose = (e, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setCreateOpen(false);
    };

    const handleDetailsDialogClose = (e, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setDetailsOpen(null);
    };

    return (
        <Box sx={{ ...props.sx }}>
            <SearchBar searchValue={search} searchOnChange={(e) => setSearch(e.target.value)} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={activeOnly}
                            onChange={(e) => setActiveOnly(e.target.checked)}
                            name="activeOnly"
                            color="primary"
                        />
                    }
                    label="Actifs seulement"
                />
                <Tooltip title="Créer un nouveau client">
                    <IconButton onClick={() => setCreateOpen(true)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </SearchBar>
            <Paper sx={{ mt: 2 }} elevation={10}>
                <List>
                    {
                        usedClients.filter(filterFunction).map((client, index) => {
                            return (
                                    <ListItemButton key={index} onClick={() => setDetailsOpen(client)}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
                                            width: "100%",
                                        }}>
                                            <Typography>{client.name}</Typography>
                                            <Typography>{!!!client.is_active && "inactif"}</Typography>
                                        </Stack>
                                    </ListItemButton>
                            )
                        })

                    }
                </List>
            </Paper>
            <Dialog maxWidth="xl" open={createOpen} onClose={handleCreateDialogClose}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h4">Ajouter un client</Typography>
                        <IconButton onClick={handleCreateDialogClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <ClientsAdd sx={{ p: 5, width: "30em" }} onSuccess={handleCreateDialogClose} />
            </Dialog>
            <Dialog maxWidth="xl" open={detailsOpen !== null} onClose={handleDetailsDialogClose}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h4">Modifier un client</Typography>
                        <IconButton onClick={handleDetailsDialogClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <ClientsDetails client={detailsOpen} sx={{ p: 5, width: "30em" }} onSuccess={handleDetailsDialogClose} />
            </Dialog>
        </Box>
    )
}
export default ClientsList