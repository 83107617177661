import React, { useState, useEffect, useRef } from "react";
import { useGetWordsQuery } from "../rtk/words";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, ChevronRight, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useGetImportancesQuery } from "../rtk/importance";
import { useGetForcesQuery, useGetQualitiesQuery, useGetValuesQuery, useGetWordscoresQuery, useSubmitFifthStepMutation, useSubmitFirstStepMutation, useSubmitFourthStepMutation, useSubmitSecondStepMutation, useSubmitThirdStepMutation } from "../rtk/form";
import { isMobile } from "react-device-detect";

const ValueArray = (props) => {
    const { value, setResults, results, forces, disabled, active, setActive } = props

    const { t } = useTranslation()
    const { data: words } = useGetWordsQuery();
    const user = useSelector((state) => state.user.user)
    const { data: qualities } = useGetQualitiesQuery();

    const getWordInLanguage = (word) => {
        const wordInLanguage = words.find((w) => w.id === word)
        if (!wordInLanguage) return ("")
        if (user.language === "en") {
            return wordInLanguage.word_en
        } else if (user.language === "sp") {
            return wordInLanguage.word_sp
        }
        else {
            return wordInLanguage.word_fr
        }
    }

    const fontSize = (isMobile ? "8px" : "")

    const forcesGroupedByQuality = forces?.reduce((r, a) => {
        // get quality
        const q = qualities.find((quality) => quality.id === a.quality)
        // if quality in value then add to array
        if (q.value === value.id) {
            r[a.quality] = [...r[a.quality] || [], a];
        }

        return r;
    }, {});

    // same as desktop, but with column instead of row
    const renderMobile = () => {
        return (
            <Paper sx={{ p: 1, mb: 2, fontSize, overflow: "auto", width: "100%" }} elevation={10} >
                <Stack>
                    <Paper
                        sx={{ mb: 1 }}
                        variant="contained"
                        color="primary"
                    >
                        <Typography variant="h4">{getWordInLanguage(value.word)}</Typography>
                    </Paper>
                    <Stack spacing={1}>
                        <Stack direction={"row"} spacing={1} >
                            {results?.map((force) => {
                                return (
                                    <Paper sx={{ height: "3em", width: "10em" }} elevation={4} >
                                        <Typography sx={{ fontSize }} textAlign={"center"} variant="body2">{getWordInLanguage(force.force?.word)}</Typography>
                                    </Paper>
                                )
                            })}
                        </Stack>
                        <Grid container spacing={1} direction={"row"}  >
                            {Object.keys(forcesGroupedByQuality).map((quality, index) => {
                                const tforces = forcesGroupedByQuality[quality]
                                //get number of results for this value
                                const indexRes = results?.filter((result) => result.force !== null).length
                                return (
                                    <Grid container item key={index} xs={3} rowSpacing={1} direction={"column"} >
                                        {tforces.map((force) => {
                                            const disabled = (indexRes !== index + 1 && indexRes !== 0) || results?.find((result) => result.force?.id === force.id) || !active

                                            return (
                                                <Grid item key={force.id} xs={2} >
                                                    <Button sx={{ p: 1, height: "100%", width: "100%", maxWidth: "5em" }} disabled={disabled} elevation={4} variant="contained" onClick={() => setResults(force)} >
                                                        <Typography sx={{ fontSize }} textOverflow={"ellipsis"} variant="body2">{getWordInLanguage(force.word)}</Typography>
                                                    </Button>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Stack>
                </Stack>
            </Paper>
        )

    }

    const renderDesktop = () => {
        return (
            <Paper sx={{ p: 1, mb: 2, fontSize, overflow: "auto", width: "66em" }} elevation={10} >
                <Stack>
                    <Paper
                        sx={{ mb: 1 }}
                        variant="contained"
                        color="primary"
                    >
                        <Typography variant="h4">{getWordInLanguage(value.word)}</Typography>
                    </Paper>
                    <Stack direction="row" spacing={1}>
                        <Stack direction={"column"} spacing={1} >
                            {results?.map((force) => {
                                return (
                                    <Paper sx={{ height: "100%", width: "10em" }} elevation={4} >
                                        <Typography sx={{ fontSize }} textAlign={"center"} variant="body2">{getWordInLanguage(force.force?.word)}</Typography>
                                    </Paper>
                                )
                            })}
                        </Stack>
                        <Grid container columns={10} spacing={1}  >
                            {Object.keys(forcesGroupedByQuality).map((quality, index) => {
                                const tforces = forcesGroupedByQuality[quality]
                                //get number of results for this value
                                const indexRes = results?.filter((result) => result.force !== null).length
                                return (
                                    <Grid container item key={index} xs={10} columns={10} rowSpacing={1} direction={"row"} >
                                        {tforces.map((force) => {
                                            const disabled = (indexRes !== index + 1 && indexRes !== 0) || results?.find((result) => result.force?.id === force.id) || !active

                                            return (
                                                <Grid item key={force.id} xs={2} >
                                                    <Button sx={{ p: 1, height: "100%", width: "100%" }} disabled={disabled} elevation={4} variant="contained" onClick={() => setResults(force)} >
                                                        <Typography sx={{ fontSize }} variant="body2">{getWordInLanguage(force.word)}</Typography>
                                                    </Button>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Stack>
                </Stack>
            </Paper>
        )
    }
    return (
        <>
            {isMobile ? renderMobile() : renderDesktop()}
        </>
    )
}

const FifthStep = (props) => {

    const { t } = useTranslation()
    const user = useSelector((state) => state.user.user)

    const { data: values } = useGetValuesQuery();
    const { data: qualities } = useGetQualitiesQuery();
    const { data: scores } = useGetWordscoresQuery();
    const { data: words } = useGetWordsQuery();
    const { data: forces } = useGetForcesQuery();
    const [submit] = useSubmitFifthStepMutation();

    const [results, setResults] = useState(null)

    const [isInstructions, setIsInstructions] = useState(true)

    useEffect(() => {
        setEmptyResults()
    }, [values])

    const setEmptyResults = () => {
        const emptyResults = {}
        if (!values) return
        values.map((value) => {
            const t_res = []
            for (let i = 0; i < 5; i++) {
                const result = {
                    force: null,
                }
                t_res.push(result)
            }
            emptyResults[value.id] = t_res
        })
        setResults(emptyResults)
    }


    useEffect(() => {
        if (!!!isInstructions) {
            props.setIsRunning(true)
        }
        else {
            props.setIsRunning(false)
        }
    }, [isInstructions])


    const renderInstructions = () => {
        return (
            <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h4">{t("fifth_step_title")}</Typography>
                {
                    isMobile ?
                        <Typography variant="body1" textAlign={"justify"}>{t("fifth_step_instructions_mobile")}</Typography>
                        :
                        <Typography variant="body1" textAlign={"justify"}>{t("fifth_step_instructions")}</Typography>}
                <Typography variant="body1" textAlign={"justify"}>{t("stop_message")}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant="outlined" onClick={props.logoff}>{t("stop")}</Button>
                    <Button variant="contained" onClick={() => setIsInstructions(false)} endIcon={<ChevronRight />}>{t("start")}</Button>
                </Box>
            </Stack>
        )
    }

    const isStepCompleted = () => {
        return Object.keys(results).every((key) => {
            const t_results = results[key]
            return t_results.every((result) => result.force !== null)
        }
        )
    }

    const handleSubmit = () => {
        if (!isStepCompleted()) return
        const flat_results = []
        Object.keys(results).map((key) => {
            const t_results = results[key]
            t_results.map((result) => {
                flat_results.push(
                    result.force.id,
                )
            })
        }
        )
        submit(flat_results)
    }

    const handleSetResults = (value, force) => {
        const t_results = results[value.id]
        const index = t_results.findIndex((result) => result.force === null)
        t_results[index].force = force
        setResults({ ...results, [value.id]: t_results })
    }

    const getCurrentValue = () => {
        // get first value with not all results filled
        const value = values.find((value) => {
            const t_results = results[value.id]
            return t_results.find((result) => result.force === null)
        }
        )
        return value
    }

    const renderStep = () => {
        if (results === null) return <CircularProgress />
        const currentValue = getCurrentValue()
        return (
            <Stack alignItems={"center"} >
                <Typography variant="body1">{t("fifth_step_recap_instruction")}</Typography>


                {
                    values.map((value, index) => (
                        <ValueArray key={value.id} value={value} results={results[value.id]} setResults={(force) => handleSetResults(value, force)} forces={forces} active={currentValue === value} />
                    ))
                }
                <Button variant="contained" onClick={handleSubmit} disabled={!isStepCompleted()}>{t("next")}</Button>
            </Stack>
        )
    }

    return (
        <Stack spacing={2}>
            {
                isInstructions ? renderInstructions() :
                    renderStep()

            }
        </Stack>

    )
}
export default FifthStep